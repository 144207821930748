import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { DataAuditByCategegory } from 'src/app/models/auditModal';
import { AccountDataService } from 'src/app/services/account-data.service';

@Component({
  selector: 'app-show-audit-details',
  templateUrl: './show-audit-details.component.html',
  styleUrls: ['./show-audit-details.component.scss']
})
export class ShowAuditDetailsComponent implements OnChanges, OnDestroy {
  @Input() auditId: string = '';
  private unsubscribe$ = new Subject<void>();
  auditData: DataAuditByCategegory[] = [];

  constructor(private accountService: AccountDataService) {
  }

  ngOnChanges() {
    if (this.auditId) {
      this.accountService.getAuditsById(this.auditId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (result) {
            this.auditData = result.responseData.data;
          }
        });
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
