<p-breadcrumb [model]="items"></p-breadcrumb>
<div class="topBarWrapper">
  <h1 class="heading-text">Account Details</h1>
  <a class="button  usageLink" [routerLink]="['/config/account', accountId, 'view','usageReport']"
    [state]="{ accountName : accountData?.setupDetails?.accountName }">Usage Report</a>
  <ng-container *hasPermission="editAccountPermissions">
    <a class="button  editAction" [routerLink]="['/config/account', accountId, 'edit']">Edit</a>
  </ng-container>
</div>
<div class="setupDetailWrapper">
  <h2 class="heighlight">Setup Details</h2>
  <div class="viewWrapper">
    <div class="setup-section">
      <div class="fieldWrapper" *ngIf="accountData?.setupDetails?.partnerEnrollmentId">
        <span class="col-sm-4 col-form-label">Parent Name:</span>
        <div class="value">{{accountData?.setupDetails?.partnerName}}</div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Account Name:</span>
        <div class="value">{{accountData?.setupDetails?.accountName}}</div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Country:</span>
        <div class="value">{{accountData?.setupDetails?.country}}</div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Account Type:</span>
        <div class="value">{{accountData?.setupDetails?.accountType}}</div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Access Type:</span>
        <div class="value">{{accountData?.setupDetails?.accessType}}</div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Screening Level:</span>
        <div class="value">{{accountData?.setupDetails?.screeningDescription}}</div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Business Expansion:</span>
        <div class="value">{{accountData?.setupDetails?.businessExpnEnabled ? 'Yes' : 'No'}}</div>
      </div>

      <!-- Show Business Expansion fields when selected -->
      <ng-container *ngIf="showBusinessExpnSection === true">
        <div class="fieldWrapper">
          <span class="col-sm-4 col-form-label">Ownership Type:</span>
          <div class="value">{{accountData?.setupDetails?.businessExpansionOptions?.ownershipTypeDesc}}</div>
        </div>

        <div class="fieldWrapper">
          <span class="col-sm-4 col-form-label">Ownership percentage:</span>
          <div class="value">{{accountData?.setupDetails?.businessExpansionOptions?.ownershipPercentage}}</div>
        </div>

        <div class="fieldWrapper">
          <span class="col-sm-4 col-form-label">Degree of Separation:</span>
          <div class="value">{{accountData?.setupDetails?.businessExpansionOptions?.degreeOfSeparation}}</div>
        </div>
      </ng-container>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Custom List:</span>
        <div class="value">{{accountData?.setupDetails?.customListEnabled ? 'Yes' : 'No'}}</div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Monitoring:</span>
        <div class="value">{{accountData?.setupDetails?.monitoringEnabled ? 'Yes' : 'No'}}</div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Login Entity ID:</span>
        <div class="value">{{accountData?.setupDetails?.loginEntityId}}</div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label">Time Zone:</span>
        <div class="value">{{accountData?.setupDetails?.timeZone}}</div>
      </div>

      <!-- Show Admin fields when RPSi is selected -->
      <ng-container *ngIf="showAdminSection === true">
        <div class="fieldWrapper">
          <span class="col-sm-4 col-form-label">Admin First Name:</span>
          <div class="value">{{accountData?.setupDetails?.adminFirstName}}</div>
        </div>

        <div class="fieldWrapper">
          <span class="col-sm-4 col-form-label">Admin Last Name:</span>
          <div class="value">{{accountData?.setupDetails?.adminLastName}}</div>
        </div>

        <div class="fieldWrapper">
          <span class="col-sm-4 col-form-label">Admin Email:</span>
          <div class="value">{{accountData?.setupDetails?.adminEmail}}</div>
        </div>
      </ng-container>
    </div>
    <div class="setup-section">
      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label bold text-blue-light1">Active Status:</span>
        <div class="value active" [class.inactive]="!active">{{active ? 'Active' : 'Inactive'}}</div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label bold text-blue-light1">Account ID:</span>
        <div class="value">{{accountData?.accountId}}</div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label bold text-blue-light1">Partner Encoded ID:</span>
        <div class="value">{{accountData?.setupDetails?.encodedPartnerId}}</div>
      </div>

      <div class="fieldWrapper">
        <span class="col-sm-4 col-form-label bold text-blue-light1">Use Config :</span>
        <div class="value active" [class.useConfig]="!active">{{useConfig ? 'Enabled' : 'Disabled'}}</div>
      </div>

      <div class="fieldWrapper sendMailAction" *ngIf="active && showAdminSection === true">
        <button class="button editAction" (click)="sendEmail(accountId)">
          {{accountData?.administrativeDetails?.mailSent ? 'Resend Email' : 'Send Email'}}
        </button>

        <button class="button editAction" *ngIf="active && ifUser && !useConfig" (click)="activeConfig(accountId)">
          Activate Config
        </button>
      </div>
    </div>
  </div>

  <h2 class="heighlight">Administrative Details</h2>
  <div class="admin-section">
    <div class="fieldWrapper">
      <span class="col-sm-4 col-form-label">Sales Contact Name:</span>
      <div class="value">{{accountData?.administrativeDetails?.salesContactName || 'NA'}}</div>
    </div>

    <div class="fieldWrapper">
      <span class="col-sm-4 col-form-label">Sales Contact Email:</span>
      <div class="value">{{accountData?.administrativeDetails?.salesContactEmail || 'NA'}}</div>
    </div>

    <div class="fieldWrapper">
      <span class="col-sm-4 col-form-label">Contract Start:</span>
      <div class="value">{{accountData?.administrativeDetails?.contractStartDate || 'NA'}}</div>
    </div>

    <div class="fieldWrapper">
      <span class="col-sm-4 col-form-label">Contract End:</span>
      <div class="value">{{accountData?.administrativeDetails?.contractEndDate || 'NA'}}</div>
    </div>

    <div class="fieldWrapper">
      <span class="col-sm-4 col-form-label">DNB Partner Name:</span>
      <div class="value">{{accountData?.administrativeDetails?.dnbPartnerName || 'NA'}}</div>
    </div>

    <div class="fieldWrapper">
      <span class="col-sm-4 col-form-label">Subscriber Number:</span>
      <div class="value">{{accountData?.administrativeDetails?.subscriberNumber || 'NA'}}</div>
    </div>

    <div class="fieldWrapper">
      <span class="col-sm-4 col-form-label">DUNS Number:</span>
      <div class="value">{{accountData?.administrativeDetails?.duns || 'NA'}}</div>
    </div>

    <div class="fieldWrapper">
      <span class="col-sm-4 col-form-label">Order Number:</span>
      <div class="value">{{accountData?.administrativeDetails?.orderNumber || 'NA'}}</div>
    </div>
  </div>
  <div class="action-wrapper">
    <a routerLink="/" class="button">Back</a>
    <button class="button showHide" (click)="showAudit = !showAudit">{{ showAudit === true ? 'Hide' : 'Show' }} Audit Logs</button>
    <button class="button export" (click)="exporAccounttActiveUsersList(accountId)">Export User List</button>
  </div>
  <div>
    <!-- <div class="topBarWrapper">
      <h2 class="heading-text">Audit Details</h2>
      <button class="button showHide" (click)="showAudit = !showAudit">Show/Hide</button>
    </div> -->
    <app-audit-trail [inputFilter]="filterObject" *ngIf="showAudit"></app-audit-trail>
  </div>
</div>
