import { DatePipe } from '@angular/common';
import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { map, Observable, of, Subject, takeUntil } from 'rxjs';
import { AccountDataService } from 'src/app/services/account-data.service';
import * as XRegExp from 'xregexp';
import '../../models/modal';
import '../../models/AccountMetaDatamodal';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit, OnChanges, OnDestroy {
  WORDS_REGEX = XRegExp('^[\\p{L}\'\\s]*$'); // Allow any language ONLY letters 
  LOGIN_ENTITY_REGEX = '[a-zA-Z-.@()0-9]*';        // Allow english ONLY letter and hyphen
  ACCOUNT_NAME_REGEX = XRegExp('^[\\p{L}\\s\-,.\\\\\/()&0-9]*$');  // Special Case for Account Name
  PARTNER_NAME_REGEX = XRegExp('^[\\p{L}\\s\-,.\'()&]*$');  // Special Case for Partner Name 
  NUMBER_REGEX = '^[0-9]+$'; // Allow ONLY Numbers 
  NUMBER_LETTERS_REGEX = '^[0-9a-zA-Z-]+$'; // Allow ONLY Numbers 
  ZERO_TO_100_REGEX = '^(100(\.0{1,2})?|([0-9]?[0-9])?|([0-9]?[0-9](\.[0-9]{1,2})))$';
  public accountForm!: FormGroup;
  partners: Option[] = [];
  ownershipTypes: Option[] = [];
  countries: Country[] = [];
  accountTypes: AccountType[] = [];
  accessTypes: AccessType[] = [];
  screeningLevels: ScreeningLevelInput[] = [];
  timeZones: TimeZone[] = [];
  accountData!: AccountData;
  accountId: string = '';
  showAdminSection = false;
  showBusinessExpnSection = false;
  isUpdating:boolean = false;
  loginEntity: string = '';
  headingText = 'Create New Account';
  partnerName = '';
  private unsubscribe$ = new Subject<void>();
  items: MenuItem[] = [
    { label: 'Account Details', routerLink: '/config/account/list' },
    { label: 'Create Account' }];
  defaultBusinessExpnValues = {
    ownershipType: 'BENF_OWRP',
    ownershipPercentage: '25',
    degreeOfSeparation: '1'
  };
  ownershipToolTip = "test tooltip <br/> abcd test ";

  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountDataService,
    private messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.data.
      pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.isUpdating = data['isUpdating'];
      });
    this.accountService.getAccountMetaData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result && result?.responseData) {
          const data: Response = result.responseData;
          this.countries = data.country;
          this.accessTypes = data.accessType;
          this.accountTypes = data.accountType;
          this.screeningLevels = data.screeningLevels;
          this.ownershipTypes = data.ownershipTypes;
          this.timeZones = data.timeZone;
          if (this.isUpdating) {
            this.ifUpdateAccount();
          }
        }
      });
    this.accountService.getPartnersAccountList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result && result?.responseData) {
          this.partners = result.responseData.partners;
        }
      });
        
    this.accountForm = this.formBuilder.group({
      accountName: new FormControl('', [Validators.required, Validators.maxLength(200), Validators.pattern(this.ACCOUNT_NAME_REGEX)]),
      partnerEnrollmentId: new FormControl({value: '', disabled: this.isUpdating}),
      country: new FormControl('', [Validators.required]),
      accountType: new FormControl('', [Validators.required]),
      accessType: new FormControl('', [Validators.required]),
      screeningLevel: new FormControl('', [Validators.required]),
      businessExpnEnabled: new FormControl('false', [Validators.required]),
      businessExpansionOptions: this.formBuilder.group({
        ownershipType: new FormControl(this.defaultBusinessExpnValues.ownershipType),
        ownershipPercentage: new FormControl(this.defaultBusinessExpnValues.ownershipPercentage),
        degreeOfSeparation: new FormControl(this.defaultBusinessExpnValues.degreeOfSeparation)
      }),
      customListEnabled: new FormControl('false', [Validators.required]),
      monitoringEnabled: new FormControl('true', [Validators.required]),
      loginEntityId: new FormControl('',[Validators.required, Validators.maxLength(50), Validators.pattern(this.LOGIN_ENTITY_REGEX)]),
      timeZone: new FormControl('', [Validators.required]),
      adminFirstName: new FormControl(''),
      adminLastName: new FormControl(''),
      adminEmail: new FormControl(''),
      salesContactName: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern(this.WORDS_REGEX)]),
      salesContactEmail: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100)]),
      contractStartDate: new FormControl('', [Validators.required]),
      contractEndDate: new FormControl('', [Validators.required]),
      dnbPartnerName: new FormControl('', [Validators.pattern(this.PARTNER_NAME_REGEX)]),
      subscriberNumber: new FormControl('', [Validators.pattern(this.NUMBER_LETTERS_REGEX)]),
      duns: new FormControl('', [Validators.pattern(this.NUMBER_REGEX)]),
      orderNumber: new FormControl('', [Validators.pattern(this.NUMBER_LETTERS_REGEX)])
    });
    this.accountForm.controls['loginEntityId'].setAsyncValidators([this.loginEntityValidator()]);
  }

  onaccessTypeChange(value: string): void {
    if (value === 'RPSi') {
      this.showAdminSection = true;
      this.accountForm.controls['adminFirstName'].setValidators([Validators.required, Validators.maxLength(50), Validators.pattern(this.WORDS_REGEX)]);
      this.accountForm.controls['adminLastName'].setValidators([Validators.required, Validators.maxLength(50), Validators.pattern(this.WORDS_REGEX)]);
      this.accountForm.controls['adminEmail'].setValidators([Validators.required, Validators.maxLength(100), Validators.email,]);
    } else {
      this.showAdminSection = false;
      this.accountForm.controls['adminFirstName'].setValidators(null);
      this.accountForm.controls['adminLastName'].setValidators(null);
      this.accountForm.controls['adminEmail'].setValidators(null);
    }
    this.accountForm.controls['adminFirstName'].updateValueAndValidity();
    this.accountForm.controls['adminLastName'].updateValueAndValidity();
    this.accountForm.controls['adminEmail'].updateValueAndValidity();
  }

  onbusinessExpnChange(value: string): void {
    if (value === 'true') {
      this.showBusinessExpnSection = true;
      this.accountForm.get('businessExpansionOptions.ownershipPercentage')?.setValidators([Validators.pattern(this.ZERO_TO_100_REGEX),Validators.max(100)]);
      this.accountForm.get('businessExpansionOptions.degreeOfSeparation')?.setValidators([Validators.min(1)]);
    } else {
      this.showBusinessExpnSection = false;
      this.accountForm.get('businessExpansionOptions.ownershipPercentage')?.setValidators(null);
      this.accountForm.get('businessExpansionOptions.degreeOfSeparation')?.setValidators(null);
    }
    this.accountForm.get('businessExpansionOptions.ownershipPercentage')?.updateValueAndValidity();
    this.accountForm.get('businessExpansionOptions.degreeOfSeparation')?.updateValueAndValidity();
  }

  clearFrom(): void {
    if (this.accountForm) {
      for (const control of Object.keys(this.accountForm.controls)) {
        this.accountForm.controls[control].patchValue(null);
        this.accountForm.controls[control].markAsUntouched();
      }
    }
  }

  validate(): void {
    if (this.accountForm.invalid) {
      for (const control of Object.keys(this.accountForm.controls)) {
        this.accountForm.controls[control].markAsTouched();
      }
      return;
    }
    let dateFormat = new DatePipe('en-US');
    let contractStartDate = dateFormat.transform(this.accountForm.controls['contractStartDate'].value, 'yyyy-MM-dd') || '';
    let contractEndDate = dateFormat.transform(this.accountForm.controls['contractEndDate'].value, 'yyyy-MM-dd') || '';
    let partnerId = this.accountForm.controls['partnerEnrollmentId'].value;
    let accountBody: AccountData = {
      setupDetails: {
        accountName: this.accountForm.controls['accountName'].value,
        partnerEnrollmentId: partnerId,
        partnerName: (this.partners.find(e => e.code === partnerId))?.code,
        country: this.accountForm.controls['country'].value,
        accountType: this.accountForm.controls['accountType'].value,
        accessType: this.accountForm.controls['accessType'].value,
        screeningLevel: this.accountForm.controls['screeningLevel'].value,
        businessExpnEnabled: this.accountForm.controls['businessExpnEnabled'].value,
        businessExpansionOptions: {
          ownershipType: this.accountForm.get('businessExpansionOptions.ownershipType')?.value,
          ownershipPercentage: this.accountForm.get('businessExpansionOptions.ownershipPercentage')?.value,
          degreeOfSeparation: this.accountForm.get('businessExpansionOptions.degreeOfSeparation')?.value
        },
        customListEnabled: this.accountForm.controls['customListEnabled'].value,
        monitoringEnabled: this.accountForm.controls['monitoringEnabled'].value,
        loginEntityId: this.accountForm.controls['loginEntityId'].value,
        timeZone: this.accountForm.controls['timeZone'].value,
        adminFirstName: this.accountForm.controls['adminFirstName'].value,
        adminLastName: this.accountForm.controls['adminLastName'].value,
        adminEmail: this.accountForm.controls['adminEmail'].value
      },
      administrativeDetails: {
        salesContactName: this.accountForm.controls['salesContactName'].value,
        salesContactEmail: this.accountForm.controls['salesContactEmail'].value,
        subscriberNumber: this.accountForm.controls['subscriberNumber'].value,
        contractStartDate: contractStartDate,
        contractEndDate: contractEndDate,
        duns: this.accountForm.controls['duns'].value,
        orderNumber: this.accountForm.controls['orderNumber'].value,
        dnbPartnerName: this.accountForm.controls['dnbPartnerName'].value
      }
    }
    if (this.isUpdating) {
      delete accountBody.setupDetails.partnerEnrollmentId;
      delete accountBody.setupDetails.partnerName;
      this.updateAccountData(accountBody);
    } else {
      this.createAccountData(accountBody);
    }
  }

  ifUpdateAccount() {
    this.activatedRoute.paramMap.subscribe(param => {
      const category = param.get('accountId')
      if (category) {
        this.getAccountById(category);
        this.headingText = 'Update Account';
      }
    })
  }

  getAccountById(id: string) {
    this.accountService.getAccountById(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result && result?.responseData) {
          this.setDataToAccountFrom(result?.responseData.accountResponse);
        }
      });
  }

  setDataToAccountFrom(accountData: AccountData) {
    let accountFormData: IAccount;
    this.accountId = accountData.accountId || '';
    let setupDetails = accountData.setupDetails;
    accountData.setupDetails.accessType = accountData.setupDetails.accessType || '';
    let administrativeDetails = accountData.administrativeDetails;
    let accountObj = this.accountTypes.find( obj =>  obj.description === accountData.setupDetails.accountType )
    let accountType = accountObj?.code;
    this.loginEntity = setupDetails.loginEntityId || '';
    accountFormData = { ...setupDetails, ...administrativeDetails };
    this.onaccessTypeChange(setupDetails.accessType);
    this.onbusinessExpnChange(setupDetails.businessExpnEnabled + '');
    accountFormData.contractStartDate = this.dateTransform(accountFormData.contractStartDate);
    accountFormData.contractEndDate = this.dateTransform(accountFormData.contractEndDate);
    this.accountForm.patchValue(accountFormData);
    this.accountForm.controls['accountType'].patchValue(accountType);
    this.accountForm.controls['businessExpnEnabled'].patchValue(accountFormData.businessExpnEnabled + '');
    this.accountForm.controls['customListEnabled'].patchValue(accountFormData.customListEnabled + '');
    this.accountForm.controls['monitoringEnabled'].patchValue(accountFormData.monitoringEnabled + '');
    this.accountForm.controls['screeningLevel'].patchValue((accountFormData.screeningLevel || '') + '');
    this.partnerName = accountData.setupDetails.partnerName || '';
  }

  dateTransform(value: string) {
    if(!value || value == "")
      return "";
    const [year, month, day] = value.split('-');
    return [month, day, year].join('/');
  }

  createAccountData(accountBody:AccountData){
    this.accountService.createAccount(accountBody)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.messageService.add({ severity: 'success', life: 10000, summary: 'Success', detail: result?.responseData?.message });
        this.router.navigate(['/config/account', result?.responseData?.accountId, 'view']);
      });
  }

  updateAccountData(accountBody:AccountData){
    this.accountService.updateAccount(accountBody, this.accountId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.messageService.add({ severity: 'success', life: 10000, summary: 'Success', detail: result?.responseData?.message });
        this.router.navigate(['/config/account', result?.responseData?.accountId, 'view']);
      });
  }

  loginEntityValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      // if account is in edit mode then loginEntityID will be checked only change happening.
      if (this.isUpdating && control.value === this.loginEntity)
        return of(null);
      else
        return this.accountService.checkIfloginEntityExists(control.value).pipe(
          map(res => {
            // if loginEntityExists is true, loginEntity exists, return true
            return res.responseData?.loginEntityExists ? { loginEntityExists: true } : null;
            // NB: Return null if there is no error
          })
        );
    };
  }

  ngOnChanges() {
    this.clearFrom();
  }
  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
