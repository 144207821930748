export const environment = {
  production: false,
  appURL: 'https://rps-apps-rps-config-portal-backend-dev.nonprod.rps.dnbtech.cloud/config/api/v1',
  oktaAuth: {
    issuer: 'https://dnb-dev.oktapreview.com/oauth2/ausag3fn7oOvYdZTC1d6',
    clientId: '0oa4idudxqDEq6L8k1d7',
    redirectUri: 'https://dev-config.rpsi.dnb.com/login/callback',
    scopes: ['openid', 'email', 'profile'],
    pkce: true,
    postLogoutRedirectUri: 'https://dev-config.rpsi.dnb.com/logout/callback'
  }
};