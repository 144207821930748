interface TransactionDetail {
    inLanguage: string;
    serviceVersion: string;
    transactionID: string;
    transactionTimestamp: string;
}
interface InquiryDetail {
    accountId?: string;
}
interface Country {
    code: string;
    description: string;
}
interface AccountType {
    code: string;
    description: string;
}
interface AccessType {
    code: string;
    description: string;
}
interface TimeZone {
    code: string;
    description: string;
}
interface ScreeningLevelInput {
    code: string;
    description: string;
}
interface Response {
    country: Country[];
    accountType: AccountType[];
    accessType: AccessType[];
    timeZone: TimeZone[];
    screeningLevels: ScreeningLevelInput[];
    dnbPartnerList: string[];
    ownershipTypes: Option[];
}
interface AccountMetaData {
    transactionDetail: TransactionDetail;
    inquiryDetail: InquiryDetail;
    responseData: Response;
}
interface GetAccountData {
    transactionDetail: TransactionDetail;
    inquiryDetail: InquiryDetail;
    responseData: {
        accountResponse: AccountData;
    }
}

interface ConfigListData {
    transactionDetail: TransactionDetail;
    inquiryDetail: InquiryDetail;
    responseData: {
        accountId: string;
        accountName: string;
        configurationCount: number;
        configurations: ConfigurationList[];
    };
}

interface CreateAccountResponse {
    transactionDetail: TransactionDetail;
    inquiryDetail: InquiryDetail;
    responseData: {
        isError: string;
        message: string;
        accountId: string;
        accountName: string;
    };
}
interface SentEmailResponse {
    transactionDetail: TransactionDetail;
    inquiryDetail: InquiryDetail;
    responseData: {
        isError: boolean;
        mailSentMessage: string;
    };
}
interface activateConfigResponse {
    transactionDetail: TransactionDetail;
    inquiryDetail: InquiryDetail;
    responseData: {
        isError: boolean;
        useConfigMessage?: string;
    };
}
interface AccountDataList {
    transactionDetail: TransactionDetail;
    inquiryDetail: InquiryDetail;
    responseData: ResponseAccountList;
}

interface loginEntityValidatorResponse {
    transactionDetail: TransactionDetail;
    inquiryDetail: InquiryDetail;
    responseData: {
        loginEntityExists: boolean;
    };
}

interface ResponseAccountList {
    activeAccounts: number;
    inactiveAccounts: number;
    activeCustomers: number;
    inactiveCustomers: number;
    accounts: AccountRow[];
    resultSummary: ResultSummaryOverall;
}

interface AccountRow {
    accountId: string;
    accountName: string;
    country: string;
    dnbPartnerName: string;
    encodedPartnerId: string;
    accountType: string;
    accessType: string;
    partnerEnrollmentName: string;
    screeningLevel: string;
    monitoringEnabled: boolean;
    configurationCount: number;
    configurations: ConfigurationList[];
    active: boolean;
    parentAccountId: string;
}

interface ConfigurationList {
    configId: string;
    configProfileName: string;
    configProfileStatus: string;
}

interface ResultSummaryOverall {
    candidatesMatchedQuantity: number;
    candidatesReturnedQuantity: number;
}

interface PartnerAccountList {
    transactionDetail: TransactionDetail;
    inquiryDetail: InquiryDetail;
    responseData: {
        partners: Option[];
    };
}