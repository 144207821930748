import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AccountDataService } from 'src/app/services/account-data.service';
import { UserService } from 'src/app/services/user.service';
import { ACCOUNT_UPDATE, ACCOUNT_ALL } from '../../constants';

@Component({
  selector: 'app-view-account-data',
  templateUrl: './view-account-data.component.html',
  styleUrls: ['./view-account-data.component.scss']
})
export class ViewAccountDataComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  showAdminSection = false;
  showBusinessExpnSection = false;
  accountData!: AccountData;
  accountId: string = "";
  active: boolean = false;
  useConfig: boolean = true;
  ifUser: boolean = false;
  showAudit = false;
  allowEmails = ['panchala@dnb.com', 'londheh@dnb.com', 'ballonet@dnb.com'];
  items: MenuItem[] = [
    { label: 'Account Details', routerLink: '/config/account/list' },
    { label: 'View Account Details' }
  ];
  filterObject = {
    type: "Account",
    id: ""
  };
  editAccountPermissions = [ACCOUNT_UPDATE, ACCOUNT_ALL];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountDataService,
    private messageService: MessageService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(user => {
      console.log(user.emailAddress);
      if (this.allowEmails.indexOf(user.emailAddress) > -1) {
          this.ifUser = true;
      }
    });
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.getAccountById(params['accountId']);
      });
  }

  getAccountById(id: string) {
    this.accountService.getAccountById(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result?.responseData) {
          this.accountData = result.responseData?.accountResponse;
          this.accountId = result.responseData?.accountResponse.accountId || "";
          this.showAdminSection = this.accountData?.setupDetails.accessType === 'RPSi';
          this.showBusinessExpnSection = this.accountData?.setupDetails?.businessExpnEnabled === true;
          this.active = this.accountData?.setupDetails?.active ? true : false;
          this.useConfig = this.accountData?.administrativeDetails?.useConfig ? true : false;
          this.filterObject.id = this.accountId;
        }
      });
  }

  sendEmail(id: string) {
    this.accountService.sendEmailNotification(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result?.responseData) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: result?.responseData?.mailSentMessage });
          this.accountData.administrativeDetails.mailSent = true;
        }
      });
  }

  activeConfig(id: string) {
    this.accountService.activeConfiguration(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result?.responseData) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: result?.responseData?.useConfigMessage });
          this.useConfig = true;
        }
      });
  }

  public exporAccounttActiveUsersList(id: string) {
    this.accountService.exportAccountActiveUsersListData(id)
    .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => this.downLoadFile(response)
      );
  }

  downLoadFile(data: any) {
    let date = new Date();
    let timeStamp = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2) + ('0' + date.getHours() ).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2) 
    let blob = new Blob([data], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = url;
    link.download = 'exported_account_users_emails' + '_' +this.accountId+ '_' + timeStamp + '.csv';
    link.click();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}