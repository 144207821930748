<p-breadcrumb [model]="items"></p-breadcrumb>
<h1 class="heading-text">Configuration Profile for <span>{{accountName}}</span></h1>
<div class="setupDetailWrapper">
  <form [formGroup]="configForm" #form="ngForm">
    <div class="configureField">
      <div class="setup-section">
        <div class="form-group">
          <label for="profileName">Profile Name:<span class="required">*</span></label>
          <div class="control-wrapper">
            <input type="text" id="profileName" name="profileName" formControlName="profileName" placeholder="" required
              class="form-control form-control-sm"
              [class.is-invalid]="configForm.controls['profileName'].invalid && (configForm.controls['profileName'].dirty || configForm.controls['profileName'].touched)">
            <div
              *ngIf="configForm.controls['profileName'].invalid && (configForm.controls['profileName'].dirty || configForm.controls['profileName'].touched)"
              class="invalid-feedback">
              <div *ngIf="configForm.controls['profileName'].errors?.['required']">
                This field is required.
              </div>
              <div *ngIf="configForm.controls['profileName'].errors?.['pattern']">
                Alphabates Allowed only
              </div>
              <div *ngIf="configForm.controls['profileName'].errors?.['maxlength']">
                Profile Name must not exceed {{configForm.controls['profileName'].errors?.['maxlength']?.requiredLength}} characters.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="descriptionNote">Description / Note: </label>
          <div class="control-wrapper descriptionNote">
            <textarea type="text" id="descriptionNote" name="descriptionNote" formControlName="descriptionNote"
              placeholder="" class="form-control form-control-sm"
              [class.is-invalid]="configForm.controls['descriptionNote'].invalid && 
              (configForm.controls['descriptionNote'].dirty || configForm.controls['descriptionNote'].touched)"></textarea>
            <div *ngIf="configForm.controls['descriptionNote'].invalid && 
              (configForm.controls['descriptionNote'].dirty || configForm.controls['descriptionNote'].touched)"
              class="invalid-feedback">
              <div *ngIf="configForm.controls['descriptionNote'].errors?.['required']">
                This field is required.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="ismonitoringEnabled">Monitoring Enabled:</label>
          <div class="control-wrapper">
            <input type="radio" value="false" formControlName="monitoringEnabled">
            <span class="mr-2">No</span>
            <input type="radio" value="true" formControlName="monitoringEnabled">
            <span>Yes</span>
          </div>
        </div>

        <div class="additionalField defaultConfig">
          <div class="form-group">
            <label for="defaultConfig">Default Config:</label>
            <div class="control-wrapper">
              <input type="radio" value="true" formControlName="defaultConfig">
              <span class="mr-2">Yes</span>
              <input type="radio" value="false" formControlName="defaultConfig">
              <span class="mr-2">No</span>
            </div>
          </div>
        </div>
      </div>
      <div class="additionalField profileStatus">
        <div class="form-group">
          <label for="profileStatus">Status:</label>
          <div class="control-wrapper">
            <input type="radio" value="Published" formControlName="profileStatus">
            <span class="mr-2">Publish</span>
            <input type="radio" value="Draft" formControlName="profileStatus">
            <span class="mr-2">Draft</span>
            <span *ngIf="isUpdating">
              <input type="radio" value="Disabled" formControlName="profileStatus">
              <span class="mr-2">Disabled</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="tabLayout-wrapper" *ngIf="watchList.tabStatus">
      <p-tabView>
        <p-tabPanel header="Watchlists & Sanctions" *ngIf="watchList.tabStatus">
          <div formGroupName="watchlistAndSancations">
            <div class="setup-section">
              <div class="form-group">
                <label for="timeFrame" class="timeFrameLabel">Time Frame for Searches : </label>
                <div class="control-wrapper">
                  <select class="form-control" id="timeFrame" formControlName="timeFrame" class="timeFrame">
                    <option *ngFor="let element of watchList.timeFrame" [ngValue]="element.code">{{element.description}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group" *ngIf="watchList.customListEnabled && isUpdating">
                <label for="customListId" class=" ">Use Custom List ID:</label>
                <div class="control-wrapper customListId">
                  <input type="text" id="customListId" name="customListId" formControlName="customListId" placeholder=""
                    class="form-control form-control-sm">
                </div>
              </div>
              <div class="form-group categoryControl">
                <label for="categories">Product Categories: </label>
                <div class="control-wrapper">
                  <p-multiSelect [options]="watchList.categories" [group]="true" defaultLabel="Selection required"
                    scrollHeight="400px" [selectedItemsLabel]="'{0} items selected'" formControlName="categories"
                    [showToggleAll]="false" [filter]="false">
                    <ng-template let-group pTemplate="group">
                      <div class="flex align-items-center">
                        <input type="checkbox" class="custom"
                          [checked]="selectedGroupLength(group.value, 'watchlistAndSancations.categories', group.items.length)"
                          (click)="setSelection($event, group.items, 'watchlistAndSancations.categories')">
                        <span>{{group.label}}</span>
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div class="content">{{item.label}} </div>
                      <i class="pi pi-info-circle" pTooltip="{{item?.tooltip}}" tooltipPosition="top"></i>
                    </ng-template>
                  </p-multiSelect>
                </div>
              </div>
              <div class="form-group subCategoryControl">
                <label for="categories">Sub Categories: </label>
                <div class="control-wrapper">
                  <div *ngFor="let choice of watchList.subProducts; let i=index">
                    <span>
                      <input type="checkbox" class="custom" [checked]="choice?.defaultOption || choice?.selected"
                        [value]="choice.code" (change)="onCheckChange($event, 'watchlistAndSancations.subProducts')">
                      {{choice.description}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Political Affiliations" *ngIf="political.tabStatus">
          <div formGroupName="politicalAffiliations">
            <div class="setup-section">
              <div class="form-group categoryControl">
                <label for="categories">PEPDesk Categories: </label>
                <div class="control-wrapper">
                  <p-multiSelect [options]="political.categories" defaultLabel="Selection required" scrollHeight="350px"
                    [selectedItemsLabel]="'{0} items selected'" formControlName="categories" [showToggleAll]="false"
                    [filter]="false" class="not-grouped">
                    <ng-template let-item pTemplate="item">
                      <div>{{item.label}}</div>
                    </ng-template>
                  </p-multiSelect>
                </div>
              </div>
              <div class="form-group subCategoryControl">
                <label for="categories">Sub Categories: </label>
                <div class="control-wrapper">
                  <div *ngFor="let choice of political.subProducts; let i=index">
                    <span>
                      <input type="checkbox" class="custom" [value]="choice.code"
                        [checked]="choice?.defaultOption || choice?.selected"
                        (change)="onCheckChange($event, 'politicalAffiliations.subProducts')">
                      {{choice.description}}
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </p-tabPanel>
        <p-tabPanel header="Adverse Media" *ngIf="adverseMedia.tabStatus">
          <div formGroupName="adverseMedia">
            <div class="setup-section">
              <div class="form-group">
                <label for="timeFrame" class="timeFrameLabel">Time Frame for Searches : </label>
                <div class="control-wrapper">
                  <select class="form-control" id="timeFrame" formControlName="timeFrame" class="timeFrame">
                    <option *ngFor="let element of watchList.timeFrame" [ngValue]="element.code">{{element.description}}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group categoryControl">
                <label for="categories">Geographic Coverage:</label>
                <div class="control-wrapper">
                  <p-multiSelect [options]="adverseMedia.categories" [group]="true" defaultLabel="Selection required"
                    scrollHeight="400px" [selectedItemsLabel]="'{0} items selected'" formControlName="categories"
                    [showToggleAll]="false" [filter]="false">
                    <ng-template let-group pTemplate="group">
                      <div class="flex align-items-center">
                        <input type="checkbox" class="custom"
                          [checked]="selectedGroupLength(group.value, 'adverseMedia.categories', group.items.length)"
                          (click)="setSelection($event, group.items, 'adverseMedia.categories')">
                        <span>{{group.label}}</span>
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div>{{item.label}}</div>
                    </ng-template>
                  </p-multiSelect>
                </div>
              </div>

              <div class="form-group categoryControl">
                <label for="eventTypes">Event Types:</label>
                <div class="control-wrapper">
                  <p-multiSelect [options]="adverseMedia.eventTypes" [group]="true" defaultLabel="Selection required"
                    scrollHeight="400px" [selectedItemsLabel]="'{0} items selected'" formControlName="eventTypes"
                    [showToggleAll]="false" [filter]="false">
                    <ng-template let-group pTemplate="group">
                      <div class="flex align-items-center">
                        <input type="checkbox" class="custom"
                          [checked]="selectedGroupLength(group.value, 'adverseMedia.eventTypes', group.items.length)"
                          (click)="setSelection($event, group.items, 'adverseMedia.eventTypes')">
                        <span>{{group.label}}</span>
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div class="content">{{item.label}} </div>
                      <i class="pi pi-info-circle" pTooltip="{{item?.tooltip}}" tooltipPosition="top"></i>
                    </ng-template>
                  </p-multiSelect>
                </div>
                <div class="form-group" *ngFor="let choice of adverseMedia.subProducts; let i=index">
                  <span>
                    <input type="checkbox" [value]="choice.code" class="custom"
                      (change)="onCheckChange($event, 'adverseMedia.subProducts')">
                    {{choice.description}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
      <button class="button submitButton mr-2" (click)="submitForm()">Save</button>
      <a routerLink="/" class="button">Cancel</a>
    </div>
  </form>
</div>
