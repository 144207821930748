interface getConfigMetaData {
  transactionDetail: TransactionDetail;
  inquiryDetail: InquiryDetail;
  responseData: ConfigurationData;
}

interface TransactionDetail {
  inLanguage: string;
  serviceVersion: string;
  transactionID: string;
  transactionTimestamp: string;
  dispatchedTimestamp?: string;
}

interface InquiryDetail { }

interface ConfigurationData {
  accountId: number;
  accountName: string;
  monitoringEnabled: boolean;
  defaultConfig: boolean;
  proileStatus?: string;
  screeningLevels: ScreeningLevel[];
  profileName?: string;
}

interface screenLevelI {
  tabStatus: boolean;
  description: string;
  timeFrame?: Option[];
  customListEnabled?: boolean;
  code: string;
  subProducts: Option[];
  categories: multiSelectArray[];
  eventTypes?: multiSelectArray[];
}

interface screenLevelAdverseMedia {
  tabStatus: boolean;
  description: string;
  timeFrame: Option[];
  code: string;
  subProducts: Option[];
  categories: multiSelectArray[];
  eventTypes: multiSelectArray[];
}

interface multiSelectArray {
  label?: string;
  value: string;
  items: {
    label: string;
    value: selectedOptions;
    tooltip?: string;
  }[] | Option[];
}

interface selectedOptions {
  code: string;
  parentCode: string
}

interface ScreeningLevel {
  code: string;
  description: string;
  customListEnabled?: boolean;
  timeFrame?: Option[];
  selectedTimeFrame?: Option;
  pressRelease?: boolean;
  customListId?: string;
  categories: EventCategory[];
  eventTypes?: SubEventCategory[];
  subProducts: Option[];
  selectedCategories: EventCategory[]
  selectedSubProducts?: Option[]
  selectedEventTypes?: SubEventCategory[]
}

interface ScreeningLevelWatchlList {
  code: string;
  description: string;
  customListEnabled?: boolean;
  timeFrame?: Option[];
  selectedTimeFrame?: Option;
  pressRelease?: boolean;
  customListId?: string;
  categories: WatchEventCategory[];
  eventTypes?: SubEventCategory[];
  subProducts: Option[];
  selectedCategories: EventCategory[]
  selectedSubProducts?: Option[]
  selectedEventTypes?: SubEventCategory[]
}

interface WatchEventCategory {
  code: string;
  description?: string;
  defaultCategory: boolean;
  options: WatchOption[];
  show?: boolean;
  selected?:boolean;
}

interface EventCategory {
  code: string;
  description?: string;
  defaultCategory: boolean;
  options: Option[];
  show?: boolean;
  selected?:boolean;
}

interface Option {
  code: string;
  description: string;
  defaultOption?: boolean;
  toolTipDescription?: string;
  selected?: boolean;
  riskCategoryId: string;
}

interface WatchOption {
  code: string;
  description: string;
  defaultOption?: boolean;
  toolTipDescription?: string;
  selected?: boolean;
  riskCategoryId: string;
}
interface SubEventCategory {
  code: string;
  description: string;
  options: Option[];
  show?: boolean;
}


interface ConfigurationRequest {
  profileName: string
  descriptionNote: string
  monitoringEnabled: boolean
  defaultConfig: boolean
  profileStatus: string
  screeningLevels: ScreeningLevelRequest[]
}

interface ScreeningLevelRequest {
  screeningLevel: string
  timeFrame?: number
  isCustomListEnabled?: string
  customListId?: string
  categories?: CategoryRequest[]
  selectedSubProducts: any[]
  pressRelease?: boolean
  selectedOptions?: string[]
  eventTypes?: EventTypeRequest[]
}

interface CategoryRequest {
  code?: string
  selectedOptions: string[]
}

interface EventTypeRequest {
  code: string
  selectedOptions: string[]
}

interface CreateConfigurationResponse {
  transactionDetail: TransactionDetail;
  inquiryDetail: InquiryDetail;
  responseData: {
    isError: string;
    message: string;
    configurationId: string;
    profileName: string;
  };
}

interface getConfigData {
  transactionDetail: TransactionDetail;
  inquiryDetail: InquiryDetail;
  responseData: configResponseData;
}

interface configResponseData {
  configurationResponse: GetConfigurationResponse
}

interface GetConfigurationResponse {
  profileName: string;
  descriptionNote: string;
  monitoringEnabled: boolean;
  defaultConfig: boolean;
  rpsConfigId: string;
  configurationStatus: string;
  screeningLevels: ScreeningLevel[];
  accountStatus: boolean;
}