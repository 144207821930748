import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { filter, map, Observable } from 'rxjs';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  public showMenu = false;
  public name$!: Observable<string>;
  public isAuthenticated$!: Observable<boolean>;

  constructor(
    private _oktaAuthStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private authService: AuthService) { }

  public ngOnInit(): void {
    this.name$ = this._oktaAuthStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
      map((authState: AuthState) => authState.idToken?.claims.name ?? '')
    );
    this.isAuthenticated$ = this._oktaAuthStateService.authState$.pipe(
      filter((s: AuthState) => !!s),
      map((s: AuthState) => s.isAuthenticated ?? false)
    );
    this.isAuthenticated$.subscribe(res => {
      if (res)
        this.authService.getAuthToken();
    })
  }

  public handleProfileIconClick(event: MouseEvent): void {
    this.showMenu = !this.showMenu;
    event.stopPropagation(); // don't propagate event to document level as we have listener that closes menu on outside clicks
  }

  public async handleLogoutClick(): Promise<void> {
    window.localStorage.removeItem('accountFilterParam');
    this.authService.revoke();
    await this._oktaAuth.signOut();
  }

  @HostListener('document:click')
  public handleDocumentClick(): void {
    this.showMenu = false;
  }
}
