<div class="wrapper">
  <p-breadcrumb [model]="items"></p-breadcrumb>
  <div class="topText">
    <h1 class="heading-text">Account Details</h1>
    <div class="buttonWrapper">
      <a routerLink="/config/account/create" class="button" *hasPermission="createAccountPermissions">Add New Account</a>
    </div>
  </div>
  <div class="grid-wrapper">
    <div class="grid-top-bar">
      <p-button label="All" styleClass="mr-2 accountButton"
      (click)="filterColumn('status', {target: {value: ''}})" value="1"></p-button>
      <p-button label="Active Accounts" badge="{{activeAccounts}}" styleClass="mr-2 accountButton"
      (click)="filterColumn('status', {target: {value: 'true'}})" value="1"></p-button>
      <p-button label="Inactive Accounts" styleClass="p-button-warning accountButton" badge="{{inactiveAccounts}}" 
      badgeClass="p-badge-danger" (click)="filterColumn('status', {target: {value: 'false'}})" value="0"></p-button>
      <div class="subAccount">
        <input type="checkbox" name="" (change)="onIndentChange($event)" class="subAccount"/> Indent Sub Accounts
      </div>
      <div class="buttonWrapper">
        <span class="customerText">Active Customers: <b>{{activeCustomers}}</b></span>
        <span class="customerText mr-2">Inactive Customers: <b>{{inactiveCustomers}}</b></span>
        <a (click)="exportActiveUsersList()" class="button exportButton mr-2">Export User List</a>
        <a (click)="exportAccountList()" class="button exportButton mr-2">Export Accounts</a>
        <a (click)="clearFilter()" class="button clearFilter">Reset Filters</a>
      </div>
    </div>
    <p-table #dt [value]="accountDataList" dataKey="accountId" [resizableColumns]="false" [autoLayout]="true"
      [paginator]="true" [alwaysShowPaginator]="true" [rows]="pageSize" [totalRecords]="totalPages" [lazy]="true"
      sortMode="single" (onLazyLoad)="handleLazyLoad($event)" paginatorDropdownAppendTo="body"
      [showCurrentPageReport]="true" [first]="first" styleClass="p-datatable-striped"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records">
      <ng-template pTemplate="header" (onPage)="onPagination()">
        <tr>
          <th class="chevronCol"></th>
          <th pSortableColumn="accountName" class="account-name-column">Account Name
            <p-sortIcon field="accountName"></p-sortIcon>
          </th>
          <th pSortableColumn="country" class="country country-column">Country
            <p-sortIcon field="country"></p-sortIcon>
          </th>
          <th pSortableColumn="dnbPartnerName" class="partner-column">D&B Partner Name
            <p-sortIcon field="dnbPartnerName"></p-sortIcon>
          </th>
          <th class="account-type-column">Account Type</th>
          <th class="access-column">Access Type</th>
          <th class="parent-column">Parent Name</th>
          <th class="screeningLevel-column">Screening Level</th>
          <th class="monitoring-column">Monitoring</th>
          <th class="status-column text-center" >Status</th>
          <th class="config-column">Configurations</th>
          <th class="usage-column">Usage Report</th>
        </tr>
        <tr class="filterRow">
          <th class="chevronCol"></th>
          <th>
            <input type="text" field="accountName" (keyup.enter)="filterColumn('accountName', $event)"
            [(ngModel)]="accountNameFilterVal" placeholder="Search" />
          </th>
          <th>
            <select (change)="filterColumn('country', $event)" [(ngModel)]="countryFilterVal">
              <option value="">Select</option>
              <option *ngFor="let obj of countries" [value]="obj.description">{{obj.description}}</option>
            </select>
          </th>
          <th>
            <select (change)="filterColumn('dnbPartnerName', $event)" [(ngModel)]="dnbPartnerNameFilterVal">
              <option value="">Select</option>
              <option *ngFor="let obj of dnbPartnerList" [value]="obj">{{obj}}</option>
            </select>
          </th>
          <th>
            <select (change)="filterColumn('accountType', $event)" [(ngModel)]="accountTypeFilterVal">
              <option value="">Select</option>
              <option *ngFor="let obj of accountTypes" [value]="obj.code">{{obj.description}}</option>
            </select>
          </th>
          <th>
            <select (change)="filterColumn('accessType', $event)" [(ngModel)]="accessTypeFilterVal">
              <option value="">Select</option>
              <option *ngFor="let obj of accessTypes" [value]="obj.code">{{obj.description}}</option>
            </select>
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
     
      </ng-template>
      <ng-template pTemplate="body" let-account let-expanded="expanded">
        <tr>
          <td>
            <button type="button" pButton pRipple [pRowToggler]="account" (click)="toggleRow(account, $event, dt)"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td>
            <a routerLink="/config/account/{{account.accountId}}/view" class="link" 
            [class.indentSubAccount] = "doSubAccountIndent === true && account.accountId !== account.parentAccountId">{{account.accountName}}</a>
          </td>
          <td>{{account.country}}</td>
          <td>{{account.dnbPartnerName || 'NA'}}</td>
          <td>{{account.accountType}}</td>
          <td>{{account.accessType}}</td>
          <td>{{account?.partnerEnrollmentName || 'NA'}}</td>
          <td>{{account.screeningLevel}}</td>
          <td>{{account.monitoringEnabled ? 'Enabled' : 'Disabled'}}</td>
          <td class="text-center">
            <div *ngIf="account.active; else deactivate">
              <button class="button active" *hasPermission="updateAccountPermissions"
                (click)="deactivateAccount(account.accountId, account.accountName)">Deactivate</button>
              <span class="active">Active</span>
            </div>
            <ng-template #deactivate>
              <button class="button reactive" *hasPermission="updateAccountPermissions"
              (click)="reactivateAccount(account.accountId, account.accountName)">Reactivate</button>
            <span class="inactive">Inactive</span>
            </ng-template>
          </td>
          <td class="configColumn">
            <span class="configCount">{{account.configurationCount}}</span>
            <ng-container *hasPermission="createConfigPermissions">
              <a routerLink="/config/configuration/{{account.accountId}}/create" *ngIf="account.active" class="link"
                pTooltip="Add Configuration" tooltipPosition="left">
                <i class="pi pi-plus"></i>
              </a>
            </ng-container>
          </td>
          <td class="reportColumn">
            <a class="link" [routerLink]="['/config/account', account.accountId, 'view','usageReport']"
            [state]="{ accountName : account.accountName }" pTooltip="View Usage Report" tooltipPosition="left">
              <i class="pi pi-list"></i>
            </a>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-account>
        <tr>
          <td></td>
          <td colspan="11">
            <div class="configWrapper">
              <div *ngFor="let item of account.configurations">
                <a routerLink="/config/configuration/account/{{account.accountId}}/config/{{item.configId}}/view"
                  class="link linkConfiguration">{{item.configProfileName}} (Config ID: {{item.configId}})</a>
                <span class="configStatus {{item.configProfileStatus}}">{{item.configProfileStatus}}</span>
                <span class="configStatus default" *ngIf="item.defaultConfig">(Default)</span>
              </div>
              <div class="noData" *ngIf="!account?.configurations?.length">
                <ng-container>Configuration Not Available</ng-container>
              </div>
            </div>
          </td>
        </tr>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="10" class="noData">
              <ng-container>No Configuration Available</ng-container>
            </td>
          </tr>
        </ng-template>
      </ng-template>
    </p-table>
  </div>
</div>

<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
