import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { MessageService } from 'primeng/api';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 400:
            case 401:
            case 403:
            case 500:
            case 302:
            case 404:
              this.messageService.add({ severity: 'error', summary: 'Error', life: 15000, detail: error.error?.responseData.message });
              break;
            default:
              this.messageService.add({ severity: 'error', summary: 'Error', life: 15000, detail: "Unexpented Error Occoured, Please contact with Administrator" });
          }
        }
        throw error;
      }));
  }
}
