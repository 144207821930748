<div class="app-container">
  <app-header></app-header>
  <div class="container-fluid">
    <router-outlet></router-outlet>
  </div>
</div>
<div class="progress-spinner" *ngIf="loadingService.isLoading | async">
  <i class="loading"></i>
</div>
<p-toast></p-toast>
