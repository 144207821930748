interface Account {
    subscriberNumber: number;
    duns: number;
    orderNumber: number;
    contractStartDate: string;
    contractEndDate: string;
    products: [];
    monitoring: boolean
}

interface IAccount {
    accountName: string;
    country: string;
    accountType: string;
    accessType: string;
    screeningLevel: string;
    screeningDescription?: string;
    businessExpnEnabled: boolean;
    businessExpansionOptions?: {
        ownershipType?: string;            
        ownershipPercentage?: string;             
        degreeOfSeparation?: string;
        ownershipTypeDesc?: string;
    }
    customListEnabled: string;
    monitoringEnabled: string;
    loginEntityId?: string;
    timeZone?: string;
    adminFirstName?: string;
    adminLastName?: string;
    adminEmail?: string;
    salesContactName: string;
    salesContactEmail: string;
    subscriberNumber: string;
    contractStartDate: string;
    contractEndDate: string;
    duns: string;
    orderNumber: string;
    dnbPartnerName?: string;
    active?: string;
    mailSent?: boolean;
}

interface SetupDetails {
    accountName: string;
    partnerEnrollmentId?: string;
    encodedPartnerId?: string;
    partnerName?: string;
    country: string;
    accountType: string;
    accessType: string;
    screeningLevel: string;
    screeningDescription?: string;   
    businessExpnEnabled: boolean;  
    businessExpansionOptions: {
        ownershipType?: string;            
        ownershipPercentage?: string;             
        degreeOfSeparation?: string;
        ownershipTypeDesc?: string;
    };
    customListEnabled: string;
    monitoringEnabled: string;
    loginEntityId?: string;             
    timeZone?: string;                  
    adminFirstName?: string;            
    adminLastName?: string;             
    adminEmail?: string;                
    active?:string;                     
}

interface AdministrativeDetails {
    salesContactName: string;
    salesContactEmail: string;
    subscriberNumber: string;
    contractStartDate: string;
    contractEndDate: string;
    duns: string;
    orderNumber: string;
    dnbPartnerName?: string;
    mailSent?: boolean;
    useConfig?: boolean;
}

interface AccountData {
    accountId?: string;
    setupDetails: SetupDetails;
    administrativeDetails: AdministrativeDetails;
}

