<div class="tabView">
    <p-tabView>
      <ng-container *ngFor="let item of auditData">
          <div class="columnData" *ngIf="item?.type === type">
            <div *ngIf="item?.previousData" class="showDataWrapper">
              <div class="subHeading">Previous Data</div>
              <div class="jsonShow" *ngFor="let ob of item.previousData | keyvalue">
                <span class="key">{{ob.key}} :</span>
                <span class="value">{{ob.value}}</span>
              </div>
            </div>
            <div *ngIf="item?.currentData" class="showDataWrapper">
              <div class="subHeading">Current Data</div>
              <div class="jsonShow" *ngFor="let ob of item.currentData | keyvalue">
                <span class="key">{{ob.key}} :</span>
                <span class="value">{{ob.value}}</span>
              </div>
            </div>
          </div>
      </ng-container>
    </p-tabView>
  </div>
  