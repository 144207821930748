import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SummaryReportResponse } from '../models/reportModal';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  appURL = environment.appURL;
  constructor(private http: HttpClient) { }

  getSummaryUsageReportlistData(params: string) {
    return this.http.get<SummaryReportResponse>(`${this.appURL}/reports/summary-report${params}`);
  }

  getDetailedUsageReport(params: string) {
    return this.http.get<any>(`${this.appURL}/reports/detailed-report${params}`, { responseType: 'text' as 'json'});
  }
}
