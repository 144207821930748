import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MenuItem, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AccountDataService } from 'src/app/services/account-data.service';
import '../../models/modal';
import { ACCOUNT_ALL, ACCOUNT_CREATE, ACCOUNT_UPDATE, CONFIGURATION_CREATE, CONFIGURATION_ALL } from '../../constants';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss'],
  providers: [ConfirmationService]
})
export class AccountListComponent implements OnInit, OnDestroy, AfterViewChecked {

  public accountDataList: Array<AccountRow> = [];
  public candidatesMatchedQuantity!: number;
  public totalPages!: number;
  public pageNum!: number;
  public pageSize: number = 10;
  public errorMessage = '';
  public sortBy: any;
  public sortOrder: string = '';
  public sort = true;
  public filterParams = new URLSearchParams();
  public filterBy: string = '';
  public accountNameFilterVal: string = '';
  public countryFilterVal: string = '';
  public dnbPartnerNameFilterVal: string = '';
  public accountTypeFilterVal: string = '';
  public accessTypeFilterVal: string = '';
  private unsubscribe$ = new Subject<void>();
  public activeAccounts = 0;
  public inactiveAccounts = 0;
  public activeCustomers = 0;
  public inactiveCustomers = 0;
  public doSubAccountIndent = false;
  createAccountPermissions = [ACCOUNT_CREATE, ACCOUNT_ALL];
  updateAccountPermissions = [ACCOUNT_UPDATE, ACCOUNT_ALL];
  createConfigPermissions = [CONFIGURATION_ALL, CONFIGURATION_CREATE]

  countries: Country[] = [];
  accountTypes: AccountType[] = [];
  accessTypes: AccessType[] = [];
  screeningLevels: ScreeningLevelInput[] = [];
  timeZones: TimeZone[] = [];
  dnbPartnerList: string[] = [];
  items: MenuItem[] = [{ label: 'Account Details', routerLink: '/config/account/list' }];

  constructor(
    private accountService: AccountDataService,
    private messageService: MessageService,
    private changeDetector: ChangeDetectorRef,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.isFilterParamSet();
    this.accountService.getAccountMetaData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result?.responseData) {
          const data: Response = result.responseData;
          this.countries = data.country;
          this.accessTypes = data.accessType;
          this.accountTypes = data.accountType;
          this.screeningLevels = data.screeningLevels;
          this.timeZones = data.timeZone;
          this.dnbPartnerList = data.dnbPartnerList;
        }
      });
  }
  public getAllData(): void {
    this.pageNum = this.pageNum ? this.pageNum : 1;
    this.pageSize = this.pageSize ? this.pageSize : 10;
    this.sortBy = this.sortBy ? this.sortBy : 'accountName';
    this.sortOrder = this.sortOrder ? this.sortOrder : 'ASC';
    this.filterParams.set('pageNo', '' + this.pageNum)
    this.filterParams.set('pageSize', '' + this.pageSize)
    this.filterParams.set('sortField', '' + this.sortBy)
    this.filterParams.set('sortOrder', '' + this.sortOrder)
    const params = '?' + this.filterParams.toString();
    window.localStorage.setItem('accountFilterParam', this.filterParams.toString());
    this.accountService
      .getAccountList(params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.accountDataList = result.responseData.accounts;
          this.activeAccounts = result.responseData.activeAccounts;
          this.inactiveAccounts = result.responseData.inactiveAccounts;
          this.activeCustomers = result.responseData.activeCustomers;
          this.inactiveCustomers = result.responseData.inactiveCustomers;
          this.totalPages = result.responseData.resultSummary.candidatesMatchedQuantity;
        }
      })
  }
  public filterColumn(columnName: string, event: any) {
    let value = event.target.value.trim();
    if (value !== '') {
      this.filterParams.set(columnName, value);
    } else {
      this.filterParams.delete(columnName);
    }
    this.pageNum = 1;
    this.getAllData();
  }
  public handleLazyLoad(event: LazyLoadEvent): void {
    this.sortBy = event.sortField;
    this.sortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    this.pageNum = Math.floor(((event.first || 1) / (event.rows || 1)) + 1);
    this.getAllData();
  }
  public get first(): number {
    return (this.pageNum - 1) * this.pageSize;
  }

  onPagination() {
    this.getAllData();
  }

  deactivateAccount(accountId: string, accountName: string) {
    this.confirmationService.confirm({
      message: 'Deactivate action will disable the ' + accountName + '. Are you sure want to perform the action ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.accountService.deactivateAccount(accountId)
          .pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
            if (result) {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: result?.responseData?.message });
            }
            this.getAllData();
          });
      }
    });
  }

  reactivateAccount(accountId: string, accountName: string) {
    this.confirmationService.confirm({
      message: 'Reactivate action will enable the ' + accountName + '. Are you sure want to perform the action ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.accountService.reactivateAccount(accountId)
          .pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
            if (result) {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: result?.responseData?.message });
            }
            this.getAllData();
          });
      }
    });
  }

  isFilterParamSet() {
    let url = window.localStorage.getItem('accountFilterParam') || '';
    if (url.length) {
      this.filterParams = new URLSearchParams(url);
      this.filterParams.forEach((value, key) => {
        switch (key) {
          case 'pageNo':
            this.pageNum = parseInt(value);
            break;
          case 'pageSize':
            this.pageSize = parseInt(value);
            break;
          case 'sortField':
            this.sortBy = value;
            break;
          case 'sortOrder':
            this.sortOrder = value;
            break;
          case 'accountName':
            this.accountNameFilterVal = value;
            this.filterParams.set(key, value);
            break;
          case 'country':
            this.countryFilterVal = value;
            this.filterParams.set(key, value);
            break;
          case 'dnbPartnerName':
            this.dnbPartnerNameFilterVal = value;
            this.filterParams.set(key, value);
            break;
          case 'accountType':
            this.accountTypeFilterVal = value;
            this.filterParams.set(key, value);
            break;
          case 'accessType':
            this.accessTypeFilterVal = value;
            this.filterParams.set(key, value);
            break;
        }
      });
    }
  }

  clearFilter() {
    window.localStorage.removeItem('accountFilterParam');
    this.filterParams = new URLSearchParams('');
    this.pageNum = 1;
    this.pageSize = 10;
    this.sortBy = 'accountName';
    this.sortOrder = 'ASC';
    this.accountNameFilterVal = '';
    this.countryFilterVal = '';
    this.dnbPartnerNameFilterVal = '';
    this.accountTypeFilterVal = '';
    this.accessTypeFilterVal = '';
    this.getAllData();
  }

  public exportAccountList() {
    var params = this.filterParams;
    params.delete('pageSize');
    params.delete('pageNo');
    params.delete('sortField');
    params.delete('sortOrder');
    this.accountService.exportAccountListData('?' + params.toString())
    .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => this.downLoadFile(response)
      );
  }

  exportActiveUsersList():void {
    this.accountService.exportActiveUsersListData()
    .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => this.downLoadFile(response)
      );
  }

  onIndentChange(e: any):void { // here e is a native event
    if (e.target.checked) {
      this.doSubAccountIndent = true;
    }
    else {
      this.doSubAccountIndent = false;
    }
  }

  downLoadFile(data: any) {
    let date = new Date();
    let timeStamp = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2) + ('0' + date.getHours() ).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2) 
    let blob = new Blob([data], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = url;
    link.download = 'exported_accounts_users_emails_' + '_' + timeStamp + '.csv';
    link.click();
  }

  toggleRow (account: AccountRow, event: any, dt: any) {
    console.log(event);
    if(event?.target['className'] !== "p-button-icon p-button-icon-left pi pi-chevron-down") {
      this.accountService.getConfigListByID(account.accountId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: ConfigListData) => {
        dt.toggleRow(event);
        account.configurations = response.responseData.configurations; 
      });
    }
  };

  // Function required to support dynamic data update in PTable and its filter contorols
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}