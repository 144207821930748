import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { UserInfo } from '../models/userModal';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUserSubject = new BehaviorSubject<UserInfo>({
    userName: '',
    roles: [''],
    emailAddress : ''
  });
  private currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  setUser(user: UserInfo) {
    this.currentUserSubject.next(user);
  }

  getCurrentUser() {
    return this.currentUser;
  }

}
