import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import '../models/modal';
import { switchMap, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { auditChangeDetails, AuditLogResponse } from '../models/auditModal';

@Injectable({
  providedIn: 'root'
})
export class AccountDataService {
  appURL = environment.appURL;
  constructor(private http: HttpClient) { }

  getAccountMetaData() {
    return this.http.get<AccountMetaData>(`${this.appURL}/accounts/metadata`)
  }

  getAccountList(params: string) {
    return this.http.get<AccountDataList>(`${this.appURL}/accounts${params}`);
  }

  getConfigListByID(id: string) {
    return this.http.get<ConfigListData>(`${this.appURL}/accounts/${id}/configurations`);
  }

  getPartnersAccountList() {
    return this.http.get<PartnerAccountList>(`${this.appURL}/accounts/partners`);
  }

  getAccountById(id: string) {
    return this.http.get<GetAccountData>(`${this.appURL}/accounts/${id}`);
  }

  createAccount(body: AccountData) {
    return (this.http.post<CreateAccountResponse>(`${this.appURL}/accounts`, body));
  }

  updateAccount(body: AccountData, id: string) {
    return (this.http.put<CreateAccountResponse>(`${this.appURL}/accounts/${id}`, body));
  }

  checkIfloginEntityExists(loginEntityID: string) {
    // debounce
    return timer(1000)
      .pipe(
        switchMap(() => {
          return this.http.get<loginEntityValidatorResponse>(`${this.appURL}/accounts/entities/${loginEntityID}`);
        })
      );
  }

  deactivateAccount(id: string) {
    return this.http.patch<CreateAccountResponse>(`${this.appURL}/accounts/${id}/deactivate`, '');
  }

  reactivateAccount(id: string) {
    return this.http.patch<CreateAccountResponse>(`${this.appURL}/accounts/${id}/activate`, '');
  }

  sendEmailNotification(id: string) {
    return this.http.post<SentEmailResponse>(`${this.appURL}/emails/${id}/sendmail`, '');
  }

  activeConfiguration(id: string) {
    return this.http.post<activateConfigResponse>(`${this.appURL}/accounts/${id}/user-configuration`, '');
  }

  getAuditsList(params: string) {
    return this.http.get<AuditLogResponse>(`${this.appURL}/audits${params}`);
  }

  getAuditsById(params: string) {
    return this.http.get<auditChangeDetails>(`${this.appURL}/audits/${params}`);
  }

  exportAccountListData(params: string) {
    return this.http.get<any>(`${this.appURL}/reports/export-accounts${params}`, { responseType: 'text' as 'json'});
  }

  exportActiveUsersListData() {
    return this.http.get<any>(`${this.appURL}/reports/accounts/active-users-emails`, { responseType: 'text' as 'json'});
  }

  exportAccountActiveUsersListData(id: string) {
    return this.http.get<any>(`${this.appURL}/reports/accounts/${id}/active-users-emails`, { responseType: 'text' as 'json'});
  }
}
