import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { ViewAccountDataComponent } from './components/view-account-data/view-account-data.component';
import { Error404Component } from './components/error404/error404.component';
import { AccountListComponent } from './components/account-list/account-list.component';
import { CreateConfigurationComponent } from './components/create-configuration/create-configuration.component';
import { ViewConfigurationComponent } from './components/view-configuration/view-configuration.component';
import { ViewUsageReportComponent } from './components/view-usage-report/view-usage-report.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { LogoutComponent } from './components/logout/logout.component';
import { AuditTrailComponent } from './components/audit-trail/audit-trail.component';
import { AuthService } from './services/auth.service';
import { ACCOUNT_CREATE, ACCOUNT_ALL, ACCOUNT_UPDATE, ACCOUNT_VIEW, CONFIGURATION_ALL, CONFIGURATION_UPDATE, CONFIGURATION_CREATE, CONFIGURATION_VIEW } from './constants';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'config/account/list',
    pathMatch: 'full'
  },
  {
    path: 'config/account/list',
    component: AccountListComponent,
    canActivate: [OktaAuthGuard],
    data: {
      expectedRole: [ACCOUNT_VIEW, CONFIGURATION_VIEW, ACCOUNT_ALL, CONFIGURATION_ALL]
    }
  },
  {
    path: 'config/account/create',
    component: CreateAccountComponent,
    canActivate: [OktaAuthGuard, AuthService],
    data: {
      isUpdating: false,
      expectedRole: [ACCOUNT_CREATE, ACCOUNT_ALL]
    }
  },
  {
    path: 'config/account/:accountId/edit',
    component: CreateAccountComponent,
    canActivate: [OktaAuthGuard, AuthService],
    data: {
      isUpdating: true,
      expectedRole: [ACCOUNT_UPDATE, ACCOUNT_ALL]
    }
  },
  {
    path: 'config/account/:accountId/view',
    component: ViewAccountDataComponent,
    canActivate: [OktaAuthGuard, AuthService],
    data: {
      expectedRole: [ACCOUNT_VIEW, ACCOUNT_UPDATE, ACCOUNT_ALL]
    }
  },
  {
    path: 'config/account/:accountId/view/usageReport',
    component: ViewUsageReportComponent,
    canActivate: [OktaAuthGuard, AuthService],
    data: {
      expectedRole: [ACCOUNT_VIEW, ACCOUNT_UPDATE, ACCOUNT_ALL]
    }
  },
  {
    path: 'config/configuration/:accountId/create',
    component: CreateConfigurationComponent,
    canActivate: [OktaAuthGuard, AuthService],
    data: {
      isUpdating: false,
      expectedRole: [CONFIGURATION_CREATE, CONFIGURATION_ALL]
    }
  },
  {
    path: 'config/configuration/account/:accountId/config/:configId/view',
    component: ViewConfigurationComponent,
    canActivate: [OktaAuthGuard, AuthService],
    data: {
      expectedRole: [CONFIGURATION_VIEW, CONFIGURATION_UPDATE, CONFIGURATION_ALL]
    }
  },
  {
    path: 'config/configuration/account/:accountId/config/:configId/edit',
    component: CreateConfigurationComponent,
    canActivate: [OktaAuthGuard, AuthService],
    data: {
      isUpdating: true,
      expectedRole: [CONFIGURATION_UPDATE, CONFIGURATION_ALL]
    }
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent
  },
  {
    path: 'logout/callback',
    component: LogoutComponent
  },
  {
    path: 'config/auditTrail', 
    component: AuditTrailComponent,
    canActivate: [OktaAuthGuard],
  },
  {
    path: '**', component: Error404Component
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule
  ]
})
export class AppRoutingModule { }
