import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {
  appURL = environment.appURL;
  currentUser: any;
  constructor(
    private http: HttpClient,
    private userService: UserService,
    public router: Router
  ) {
    this.userService.getCurrentUser().subscribe(user => {
      this.currentUser = user;
    });
  }

  getAuthToken() {
    return this.http.get<any>(`${this.appURL}/users/roles`)
      .subscribe({
        next: (res: any) => {
          this.userService.setUser(res.responseData);
        },
        error: (e) => {
          this.router.navigate(['/logout/callback']);
        }
      });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    // this will be passed from the route config
    // on the data property
    const expectedRole = route.data['expectedRole'] || [];
    let hasPermission = false;
    if (this.currentUser && this.currentUser.roles) {
      for (const checkPermission of expectedRole) {
        const permissionFound = this.currentUser.roles
          .find((x: string) => x.toUpperCase() === checkPermission.toUpperCase());
        if (permissionFound) {
          hasPermission = true;
        }
      }
    }
    if (!hasPermission) {
      this.router.navigate(['/'])
    }
    return hasPermission;
  }

  revoke(){
    this.http.delete<any>(`${this.appURL}/users/token`).subscribe();
  }
}
