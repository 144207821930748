<div class="top-bar fixed-top">
  <a routerLink="/" class="logo-link"><img src="/assets/images/dnb_full_logo.png" alt="Dun and Bradstreet RPS" /> </a>
  <div class="seprator"></div>
  <div class="project_text">RPS Configuration Portal</div>

  <div class="profile-container" *ngIf="(isAuthenticated$ | async) === true">
    <p *ngIf="name$ | async as name" style="margin: 8px;">
      Hello {{name}}
    </p>
    <img class="profile-icon" src="/assets/images/user.svg" (click)="handleProfileIconClick($event)" />
    <div class="profile-menu" *ngIf="showMenu">
      <a routerLink="/config/auditTrail" class="menu-item">Audit Trail</a>
      <div class="menu-item" (click)="handleLogoutClick()">Logout</div>
    </div>
  </div>

</div>
