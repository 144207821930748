import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { UsageSummary } from 'src/app/models/reportModal';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-view-usage-report',
  templateUrl: './view-usage-report.component.html',
  styleUrls: ['./view-usage-report.component.scss']
})
export class ViewUsageReportComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private accountId = 0;
  items: MenuItem[] = [];


  public usageList: Array<UsageSummary> = [];
  public candidatesMatchedQuantity!: number;
  public totalPages!: number;
  public pageNum!: number;
  public pageSize: number = 12;
  public errorMessage = '';
  public sort = false;
  public filterParams = new URLSearchParams();
  public filterBy: string = '';

  public maxDate = new Date();
  public toDate = new Date();
  public fromDate!: Date;
  public showGrid = false;

  public message = '';
  public error = false;
  public accountName = '';
  public entityID = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private reportService: ReportService
  ) {
    this.maxDate.setDate(this.maxDate.getDate());
    this.toDate.setDate(this.toDate.getDate());
  }

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.accountId = params['accountId'];
        this.items = [{ label: 'Account Details', routerLink: '/config/account/list' },
        { label: 'View Account Details', routerLink: '/config/account/' + this.accountId + '/view/' },
        { label: 'Customer Usage Summary' }]
      });
    this.accountName = history.state.accountName;
  }

  fetchSummary() {
    if (this.validateDates()) {
      this.showGrid = true;
      this.error = false;
      this.getAllData();
    }
    else {
      this.showGrid = false;
      this.error = true;

    }
  }

  validateDates() {
    let validate = false;
    if (this.toDate !== null && this.toDate !== undefined && this.fromDate !== null && this.fromDate !== undefined) {
      if (this.toDate >= this.fromDate) {
        var diff = Math.abs(this.fromDate.getTime() - this.toDate.getTime());
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        if (diffDays <= 365) {
          this.error = false;
          this.showGrid = true;
          validate = true;
        }
        else {
          this.message = 'Date range should not be grater than 365 days';
          validate = false;
        }
      }
      else {
        this.message = '\'To\' date should be grater than from date';
        validate = false;
      }
    }
    else {
      this.message = 'Please enter valid dates';
      validate = false;
    }
    return validate;
  }

  exportDetails() {
    let fromDate = this.dateTransform(this.fromDate);
    let toDate = this.dateTransform(this.toDate);
    this.filterParams.set('accountId', '' + this.accountId)
    this.filterParams.set('fromDate', '' + fromDate + ' 00:00:00')
    this.filterParams.set('toDate', '' + toDate + ' 23:59:59')
    const params = '?' + this.filterParams.toString();

    this.reportService
      .getDetailedUsageReport(params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => this.downLoadFile(response)
      );
  }

  public getAllData(): void {
    let fromDate = this.dateTransform(this.fromDate);
    let toDate = this.dateTransform(this.toDate);
    this.pageNum = this.pageNum || 1;
    this.pageSize = this.pageSize ? this.pageSize : 12;
    this.filterParams.set('pageNo', '' + this.pageNum)
    this.filterParams.set('pageSize', '' + this.pageSize)
    this.filterParams.set('accountId', '' + this.accountId)
    this.filterParams.set('fromDate', '' + fromDate + ' 00:00:00')
    this.filterParams.set('toDate', '' + toDate + ' 23:59:59')
    const params = '?' + this.filterParams.toString();
    this.reportService
      .getSummaryUsageReportlistData(params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.entityID = result.responseData.entityId;
          this.usageList = result.responseData.data;
          this.totalPages = result.responseData.resultSummary.candidatesMatchedQuantity;
        }
      });
  }

  public handleLazyLoad(event: LazyLoadEvent): void {
    this.pageNum = Math.floor(((event.first || 1) / (event.rows || 1)) + 1);
    this.getAllData();
  }

  public get first(): number {
    return (this.pageNum - 1) * this.pageSize;
  }

  onPagination() {
    this.getAllData();
  }

  dateTransform(value: Date) {
    const [year, month, day] = [
      value.getFullYear(),
      (value.getMonth() + 1).toString().padStart(2, '0'),
      value.getDate().toString().padStart(2, '0')];
    return [year, month, day].join('-');
  }

  downLoadFile(data: any) {
    let date = new Date();
    let timeStamp = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2) + ('0' + date.getHours() ).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2) 
    let blob = new Blob([data], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = url;
    link.download = 'usage_report_' + this.entityID + '_' + timeStamp + '.csv';
    link.click();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
