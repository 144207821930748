import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { LoadingService } from '../services/loading.service';
import { catchError, finalize, Observable, tap } from 'rxjs';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoadingService) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    this.requests.splice(i, 1);
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let lastResponse: HttpEvent<any>;
    let error: HttpErrorResponse;
    this.requests.push(req);
    this.loaderService.isLoading.next(true);
    return Observable.create((observer: { next: (arg0: HttpResponse<any>) => void; error: (arg0: any) => void; complete: () => void; }) => {
      const subscription = next.handle(req)
        .pipe(
          tap((response: HttpEvent<any>) => {
            lastResponse = response;
          }),
          catchError((error) => error),
          finalize(() => {
            if (lastResponse.type === HttpEventType.Sent && !error) {
              // 'aborted request' to handle canceled requests
              this.removeRequest(req);
            }
          }))
        .subscribe({
          next: event => {
            if (event instanceof HttpResponse) {
              observer.next(event);
            }
          },
          error: err => {
            this.removeRequest(req);
            observer.error(err);
          },
          complete: () => {
            this.removeRequest(req);
            observer.complete();
          }
        });
      return () => {
        subscription.unsubscribe();
      };
    });
  }
}