import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { AppComponent } from './app.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AccountListComponent } from './components/account-list/account-list.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Error404Component } from './components/error404/error404.component';
import { ViewAccountDataComponent } from './components/view-account-data/view-account-data.component';
import { AppRoutingModule } from './app-routing.module';
import { MessageService } from 'primeng/api';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { CreateConfigurationComponent } from './components/create-configuration/create-configuration.component';
import { ViewConfigurationComponent } from './components/view-configuration/view-configuration.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LogoutComponent } from './components/logout/logout.component';
import { ShowAuditDetailsComponent } from './components/show-audit-details/show-audit-details.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { environment } from 'src/environments/environment';
import { AuditTrailComponent } from './components/audit-trail/audit-trail.component';
import { ShowAuditWithoutTabComponent } from './components/show-audit-without-tab/show-audit-without-tab.component';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { ViewUsageReportComponent } from './components/view-usage-report/view-usage-report.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BadgeModule } from 'primeng/badge';

const oktaAuth = new OktaAuth(environment.oktaAuth);

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AccountListComponent,
    CreateAccountComponent,
    Error404Component,
    ViewAccountDataComponent,
    CreateConfigurationComponent,
    ViewConfigurationComponent,
    ShowAuditDetailsComponent,
    AuditTrailComponent,
    LogoutComponent,
    ShowAuditWithoutTabComponent,
    HasPermissionDirective,
    ViewUsageReportComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ScrollingModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    RouterModule.forRoot([]),
    HttpClientModule,
    DynamicDialogModule,
    DialogModule,
    CalendarModule,
    CheckboxModule,
    InputSwitchModule,
    MultiSelectModule,
    AppRoutingModule,
    ToastModule,
    TableModule,
    TabViewModule,
    TooltipModule,
    ConfirmDialogModule,
    BreadcrumbModule,
    OverlayPanelModule,
    OktaAuthModule,
    BadgeModule
  ],
  providers: [MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: OKTA_CONFIG, useValue: { oktaAuth } }],
  bootstrap: [AppComponent]
})
export class AppModule { }
