<p-breadcrumb [model]="items"></p-breadcrumb>
<h1 class="heading-text">{{headingText}}</h1>
<div class="setupDetailWrapper">

  <h2 class="heighlight">Setup Details</h2>
  <form [formGroup]="accountForm" #form="ngForm">
    <div class="setup-section">
      <div class="form-group">
        <label for="partnerEnrollmentId" class="col-sm-4 col-form-label">Parent Name </label>
        <div class="control-wrapper" *ngIf="!isUpdating">
          <select class="form-control" id="partnerEnrollmentId" formControlName="partnerEnrollmentId"
            [class.is-invalid]="accountForm.controls['partnerEnrollmentId'].invalid && (accountForm.controls['partnerEnrollmentId'].dirty || accountForm.controls['partnerEnrollmentId'].touched)">
            <option value="">Select </option>
            <option *ngFor="let obj of partners" [ngValue]="obj.code">{{obj.description}}</option>
          </select>
        </div>
        <div class="control-wrapper" *ngIf="isUpdating">
          {{partnerName}}
        </div>
      </div>

      <div class="form-group">
        <label for="accountName" class="col-sm-4 col-form-label">Customer Name <span class="required">*</span></label>
        <div class="control-wrapper">
          <input type="text" id="accountName" name="accountName" formControlName="accountName" placeholder="" required
            class="form-control form-control-sm"
            [class.is-invalid]="accountForm.controls['accountName'].invalid && (accountForm.controls['accountName'].dirty || accountForm.controls['accountName'].touched)">
          <div
            *ngIf="accountForm.controls['accountName'].invalid && (accountForm.controls['accountName'].dirty || accountForm.controls['accountName'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['accountName'].errors?.['required']">
              This field is required.
            </div>
            <div *ngIf="accountForm.controls['accountName'].errors?.['pattern']">
              Please enter valid Account Name
            </div>
            <div *ngIf="accountForm.controls['accountName'].errors?.['maxlength']">
              Customer Name must not exceed
              {{accountForm.controls['accountName'].errors?.['maxlength']?.requiredLength}} characters.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="country" class="col-sm-4 col-form-label">Country <span class="required">*</span></label>
        <div class="control-wrapper">
          <select class="form-control" id="country" formControlName="country"
            [class.is-invalid]="accountForm.controls['country'].invalid && (accountForm.controls['country'].dirty || accountForm.controls['country'].touched)">
            <option value="">Select </option>
            <option *ngFor="let obj of countries" [ngValue]="obj.description">{{obj.description}}</option>
          </select>
          <div
            *ngIf="accountForm.controls['country'].invalid && (accountForm.controls['country'].dirty || accountForm.controls['country'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['country'].errors?.['required']">
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="accountType" class="col-sm-4 col-form-label">Type <span class="required">*</span></label>
        <div class="control-wrapper">
          <select class="form-control" id="accountType" formControlName="accountType"
            [class.is-invalid]="accountForm.controls['accountType'].invalid && (accountForm.controls['accountType'].dirty || accountForm.controls['accountType'].touched)">
            <option value="">Select </option>
            <option *ngFor="let obj of accountTypes" [ngValue]="obj.code">{{obj.description}}</option>
          </select>
          <div
            *ngIf="accountForm.controls['accountType'].invalid && (accountForm.controls['accountType'].dirty || accountForm.controls['accountType'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['accountType'].errors?.['required']">
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="accessType" class="col-sm-4 col-form-label">Access <span class="required">*</span></label>
        <div class="control-wrapper">
          <select class="form-control" id="accessType" formControlName="accessType"
            (change)="onaccessTypeChange(accountForm.controls['accessType'].value)"
            [class.is-invalid]="accountForm.controls['accessType'].invalid && (accountForm.controls['accessType'].dirty || accountForm.controls['accessType'].touched)">
            <option value="">Select </option>
            <option *ngFor="let obj of accessTypes" [ngValue]="obj.code">{{obj.description}}</option>
          </select>
          <div
            *ngIf="accountForm.controls['accessType'].invalid && (accountForm.controls['accessType'].dirty || accountForm.controls['accessType'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['accessType'].errors?.['required']">
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="screeningLevel" class="col-sm-4 col-form-label">Screen Level <span class="required">*</span></label>
        <div class="control-wrapper">
          <select class="form-control" id="screeningLevel" formControlName="screeningLevel"
            [class.is-invalid]="accountForm.controls['screeningLevel'].invalid && (accountForm.controls['screeningLevel'].dirty || accountForm.controls['screeningLevel'].touched)">
            <option value="">Select </option>
            <option *ngFor="let obj of screeningLevels" [ngValue]="obj.code">{{obj.description}}</option>
          </select>
          <div
            *ngIf="accountForm.controls['screeningLevel'].invalid && (accountForm.controls['screeningLevel'].dirty || accountForm.controls['screeningLevel'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['screeningLevel'].errors?.['required']">
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="isbusinessExpnEnabled" class="col-sm-4 col-form-label">Business Expansion <span
            class="required">*</span></label>
        <div class="control-wrapper">
          <input type="radio" value="false" formControlName="businessExpnEnabled"
            (change)="onbusinessExpnChange(accountForm.controls['businessExpnEnabled'].value)">
          <span class="mr-2">No</span>
          <input type="radio" value="true" formControlName="businessExpnEnabled"
            (change)="onbusinessExpnChange(accountForm.controls['businessExpnEnabled'].value)">
          <span>Yes</span>
        </div>
      </div>
      <!-- Show Bussiness Expansion fields when RPSi is selected -->
      <ng-container *ngIf="showBusinessExpnSection === true">
        <div formGroupName="businessExpansionOptions">
          <div class="form-group">
            <label for="ownershipType" class="col-sm-4 col-form-label">Ownership Type </label>
            <div class="control-wrapper ownershipType">
              <select class="form-control" id="ownershipType" formControlName="ownershipType"
                [class.is-invalid]="accountForm.get('businessExpansionOptions.ownershipType')?.invalid && (accountForm.get('businessExpansionOptions.ownershipType')?.dirty || accountForm.get('businessExpansionOptions.ownershipType')?.touched)">
                <option value="">Select </option>
                <option *ngFor="let obj of ownershipTypes" [ngValue]="obj.code">{{obj.description}}</option>
              </select>
              <div
                *ngIf="accountForm.get('businessExpansionOptions.ownershipType')?.invalid && (accountForm.get('businessExpansionOptions.ownershipType')?.dirty || accountForm.get('businessExpansionOptions.ownershipType')?.touched)"
                class="invalid-feedback">
                <div *ngIf="accountForm.get('businessExpansionOptions.ownershipType')?.errors?.['required']">
                  This field is required.
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="ownershipPercentage" class="col-sm-4 col-form-label">Ownership percentage </label>
            <div class="control-wrapper ownershipPercentage">
              <input type="text" id="ownershipPercentage" name="ownershipPercentage"
                formControlName="ownershipPercentage" placeholder="" class="form-control form-control-sm"
                [class.is-invalid]="accountForm.get('businessExpansionOptions.ownershipPercentage')?.invalid && (accountForm.get('businessExpansionOptions.ownershipPercentage')?.dirty || accountForm.get('businessExpansionOptions.ownershipPercentage')?.touched)">
              <span> %</span>
              <div
                *ngIf="accountForm.get('businessExpansionOptions.ownershipPercentage')?.invalid && (accountForm.get('businessExpansionOptions.ownershipPercentage')?.dirty || accountForm.get('businessExpansionOptions.ownershipPercentage')?.touched)"
                class="invalid-feedback">
                <div *ngIf="accountForm.get('businessExpansionOptions.ownershipPercentage')?.errors?.['required']">
                  This field is required.
                </div>
                <div *ngIf="accountForm.get('businessExpansionOptions.ownershipPercentage')?.errors?.['pattern']">
                  Please enter valid values (0.00 to 100).
                </div>
                <div *ngIf="accountForm.get('businessExpansionOptions.ownershipPercentage')?.errors?.['max']">
                  Percentage has maximum limit of 100.
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="degreeOfSeparation" class="col-sm-4 col-form-label">Degree of Separation </label>
            <div class="control-wrapper degreeOfSeparation">
              <input type="text" id="degreeOfSeparation" name="degreeOfSeparation" formControlName="degreeOfSeparation"
                placeholder="" class="form-control form-control-sm"
                [class.is-invalid]="accountForm.get('businessExpansionOptions.degreeOfSeparation')?.invalid && (accountForm.get('businessExpansionOptions.degreeOfSeparation')?.dirty || accountForm.get('businessExpansionOptions.degreeOfSeparation')?.touched)">
                <div *ngIf="accountForm.get('businessExpansionOptions.degreeOfSeparation')?.invalid && (accountForm.get('businessExpansionOptions.degreeOfSeparation')?.dirty || accountForm.get('businessExpansionOptions.degreeOfSeparation')?.touched)"
                class="invalid-feedback">
                <div *ngIf="accountForm.get('businessExpansionOptions.degreeOfSeparation')?.errors?.['required']">
                  This field is required.
                </div>
                <div *ngIf="accountForm.get('businessExpansionOptions.degreeOfSeparation')?.errors?.['min']">
                  Please enter 1 or grater number.
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="form-group">
        <label for="isCustomListEnabled" class="col-sm-4 col-form-label">Custom List <span
            class="required">*</span></label>
        <div class="control-wrapper">
          <input type="radio" value="false" formControlName="customListEnabled">
          <span class="mr-2">No</span>
          <input type="radio" value="true" formControlName="customListEnabled">
          <span>Yes</span>
        </div>
      </div>

      <div class="form-group">
        <label for="monitoring" class="col-sm-4 col-form-label">Monitoring <span class="required">*</span></label>
        <div class="control-wrapper">
          <input type="radio" value="false" formControlName="monitoringEnabled">
          <span class="mr-2">No</span>
          <input type="radio" value="true" formControlName="monitoringEnabled">
          <span>Yes</span>
        </div>
      </div>

      <div class="form-group">
        <label for="loginEntityId" class="col-sm-4 col-form-label">Login Entity ID <span
            class="required">*</span></label>
        <div class="control-wrapper">
          <input type="text" id="loginEntityId" name="loginEntityId" formControlName="loginEntityId" placeholder=""
            required class="form-control form-control-sm"
            [class.is-invalid]="accountForm.controls['loginEntityId'].invalid && (accountForm.controls['loginEntityId'].dirty || accountForm.controls['loginEntityId'].touched)">
          <div
            *ngIf="accountForm.controls['loginEntityId'].invalid && (accountForm.controls['loginEntityId'].dirty || accountForm.controls['loginEntityId'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['loginEntityId'].errors?.['required']">
              This field is required.
            </div>
            <div *ngIf="accountForm.controls['loginEntityId'].errors?.['pattern']">
              Please provide correct ID
            </div>
            <div *ngIf="accountForm.controls['loginEntityId'].errors?.['loginEntityExists']">
              Login Entity ID is alreay Exists, please provide unique ID
            </div>
            <div *ngIf="accountForm.controls['loginEntityId'].errors?.['maxlength']">
              Login Entity ID must not exceed
              {{accountForm.controls['loginEntityId'].errors?.['maxlength']?.requiredLength}} characters.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="timeZone" class="col-sm-4 col-form-label">Time Zone <span class="required">*</span></label>
        <div class="control-wrapper">
          <select class="form-control" id="timeZone" formControlName="timeZone"
            [class.is-invalid]="accountForm.controls['timeZone'].invalid && (accountForm.controls['timeZone'].dirty || accountForm.controls['timeZone'].touched)">
            <option value="">Select </option>
            <option *ngFor="let obj of timeZones" [ngValue]="obj.description">{{obj.description}}</option>
          </select>
          <div
            *ngIf="accountForm.controls['timeZone'].invalid && (accountForm.controls['timeZone'].dirty || accountForm.controls['timeZone'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['timeZone'].errors?.['required']">
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <!-- Show Admin fields when RPSi is selected -->
      <ng-container *ngIf="showAdminSection === true">
        <div class="form-group">
          <label for="adminFirstName" class="col-sm-4 col-form-label">Admin First Name <span
              class="required">*</span></label>
          <div class="control-wrapper">
            <input type="text" id="adminFirstName" name="adminFirstName" formControlName="adminFirstName" placeholder=""
              required class="form-control form-control-sm"
              [class.is-invalid]="accountForm.controls['adminFirstName'].invalid && (accountForm.controls['adminFirstName'].dirty || accountForm.controls['adminFirstName'].touched)">
            <div
              *ngIf="accountForm.controls['adminFirstName'].invalid && (accountForm.controls['adminFirstName'].dirty || accountForm.controls['adminFirstName'].touched)"
              class="invalid-feedback">
              <div *ngIf="accountForm.controls['adminFirstName'].errors?.['required']">
                This field is required.
              </div>
              <div *ngIf="accountForm.controls['adminFirstName'].errors?.['pattern']">
                Alphabates/space allowed only
              </div>
              <div *ngIf="accountForm.controls['adminFirstName'].errors?.['maxlength']">
                Admin first name must not exceed
                {{accountForm.controls['adminFirstName'].errors?.['maxlength']?.requiredLength}} characters.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="adminLastName" class="col-sm-4 col-form-label">Admin Last Name <span
              class="required">*</span></label>
          <div class="control-wrapper">
            <input type="text" id="adminLastName" name="adminLastName" formControlName="adminLastName" placeholder=""
              required class="form-control form-control-sm"
              [class.is-invalid]="accountForm.controls['adminLastName'].invalid && (accountForm.controls['adminLastName'].dirty || accountForm.controls['adminLastName'].touched)">
            <div
              *ngIf="accountForm.controls['adminLastName'].invalid && (accountForm.controls['adminLastName'].dirty || accountForm.controls['adminLastName'].touched)"
              class="invalid-feedback">
              <div *ngIf="accountForm.controls['adminLastName'].errors?.['required']">
                This field is required.
              </div>
              <div *ngIf="accountForm.controls['adminLastName'].errors?.['pattern']">
                Alphabates/space allowed only
              </div>
              <div *ngIf="accountForm.controls['adminLastName'].errors?.['maxlength']">
                Admin last name must not exceed
                {{accountForm.controls['adminLastName'].errors?.['maxlength']?.requiredLength}} characters.
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="adminEmail" class="col-sm-4 col-form-label">Admin Email <span class="required">*</span></label>
          <div class="control-wrapper">
            <input type="text" id="adminEmail" name="adminEmail" formControlName="adminEmail" placeholder="" required
              class="form-control form-control-sm"
              [class.is-invalid]="accountForm.controls['adminEmail'].invalid && (accountForm.controls['adminEmail'].dirty || accountForm.controls['adminEmail'].touched)">
            <div
              *ngIf="accountForm.controls['adminEmail'].invalid && (accountForm.controls['adminEmail'].dirty || accountForm.controls['adminEmail'].touched)"
              class="invalid-feedback">
              <div *ngIf="accountForm.controls['adminEmail'].errors?.['required']">
                This field is required.
              </div>
              <div *ngIf="accountForm.controls['adminEmail'].errors?.['email']">
                Please provide correct Email id
              </div>
              <div *ngIf="accountForm.controls['adminEmail'].errors?.['maxlength']">
                Admin Email must not exceed {{accountForm.controls['adminEmail'].errors?.['maxlength']?.requiredLength}}
                characters.
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <h2 class="heighlight">Administrative Details</h2>
    <div class="admin-section">
      <div class="form-group">
        <label for="salesContactName" class="col-sm-4 col-form-label">Sales Contact Name <span
            class="required">*</span></label>
        <div class="control-wrapper">
          <input type="text" id="salesContactName" name="salesContactName" formControlName="salesContactName"
            placeholder="" required class="form-control form-control-sm"
            [class.is-invalid]="accountForm.controls['salesContactName'].invalid && (accountForm.controls['salesContactName'].dirty || accountForm.controls['salesContactName'].touched)">
          <div
            *ngIf="accountForm.controls['salesContactName'].invalid && (accountForm.controls['salesContactName'].dirty || accountForm.controls['salesContactName'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['salesContactName'].errors?.['required']">
              This field is required.
            </div>
            <div *ngIf="accountForm.controls['salesContactName'].errors?.['pattern']">
              Alphabates/space allowed only
            </div>
            <div *ngIf="accountForm.controls['salesContactName'].errors?.['maxlength']">
              Sales Contact Name must not exceed
              {{accountForm.controls['salesContactName'].errors?.['maxlength']?.requiredLength}} characters.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="salesContactEmail" class="col-sm-4 col-form-label">Sales Contact Email <span
            class="required">*</span></label>
        <div class="control-wrapper">
          <input type="text" id="salesContactEmail" name="salesContactEmail" formControlName="salesContactEmail"
            placeholder="" required class="form-control form-control-sm"
            [class.is-invalid]="accountForm.controls['salesContactEmail'].invalid && (accountForm.controls['salesContactEmail'].dirty || accountForm.controls['salesContactEmail'].touched)">
          <div
            *ngIf="accountForm.controls['salesContactEmail'].invalid && (accountForm.controls['salesContactEmail'].dirty || accountForm.controls['salesContactEmail'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['salesContactEmail'].errors?.['required']">
              This field is required.
            </div>
            <div *ngIf="accountForm.controls['salesContactEmail'].errors?.['email']">
              Please provide correct Email id
            </div>
            <div *ngIf="accountForm.controls['salesContactEmail'].errors?.['maxlength']">
              Sales Contact Email must not exceed
              {{accountForm.controls['salesContactEmail'].errors?.['maxlength']?.requiredLength}} characters.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="contractStartDate" class="col-sm-4 col-form-label">Contract Start <span
            class="required">*</span></label>
        <div class="control-wrapper">
          <p-calendar appendTo="body" id="contractStartDate" name="contractStartDate"
            formControlName="contractStartDate" [showIcon]="true" dateFormat="mm/dd/yy" placeholder="MM/DD/YYYY"
            [class.is-invalid]="accountForm.controls['contractStartDate'].invalid && (accountForm.controls['contractStartDate'].dirty || accountForm.controls['contractStartDate'].touched)">
          </p-calendar>
          <div
            *ngIf="accountForm.controls['contractStartDate'].invalid && (accountForm.controls['contractStartDate'].dirty || accountForm.controls['contractStartDate'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['contractStartDate'].errors?.['required']">
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="contractEndDate" class="col-sm-4 col-form-label">Contract End <span
            class="required">*</span></label>
        <div class="control-wrapper">
          <p-calendar appendTo="body" id="contractEndDate" name="contractEndDate" placeholder="MM/DD/YYYY"
            formControlName="contractEndDate" [showIcon]="true" dateFormat="mm/dd/yy"
            [class.is-invalid]="accountForm.controls['contractEndDate'].invalid && (accountForm.controls['contractEndDate'].dirty || accountForm.controls['contractEndDate'].touched)">
          </p-calendar>
          <div
            *ngIf="accountForm.controls['contractEndDate'].invalid && (accountForm.controls['contractEndDate'].dirty || accountForm.controls['contractEndDate'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['contractEndDate'].errors?.['required']">
              This field is required.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="dnbPartnerName" class="col-sm-4 col-form-label">DNB Partner Name </label>
        <div class="control-wrapper">
          <input type="text" id="dnbPartnerName" name="dnbPartnerName" formControlName="dnbPartnerName" placeholder=""
            class="form-control form-control-sm"
            [class.is-invalid]="accountForm.controls['dnbPartnerName'].invalid && (accountForm.controls['dnbPartnerName'].dirty || accountForm.controls['dnbPartnerName'].touched)">
          <div
            *ngIf="accountForm.controls['dnbPartnerName'].invalid && (accountForm.controls['dnbPartnerName'].dirty || accountForm.controls['dnbPartnerName'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['dnbPartnerName'].errors?.['pattern']">
              Please enter valid Partner Name.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="subscriberNumber" class="col-sm-4 col-form-label">Subscriber Number </label>
        <div class="control-wrapper">
          <input type="text" id="subscriberNumber" name="subscriberNumber" formControlName="subscriberNumber"
            placeholder="" class="form-control form-control-sm"
            [class.is-invalid]="accountForm.controls['subscriberNumber'].invalid && (accountForm.controls['subscriberNumber'].dirty || accountForm.controls['subscriberNumber'].touched)">
          <div
            *ngIf="accountForm.controls['subscriberNumber'].invalid && (accountForm.controls['subscriberNumber'].dirty || accountForm.controls['subscriberNumber'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['subscriberNumber'].errors?.['required']">
              This field is required.
            </div>
            <div *ngIf="accountForm.controls['subscriberNumber'].errors?.['pattern']">
              Enter numbers and/or letters.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="dunsNumber" class="col-sm-4 col-form-label">DUNS Number </label>
        <div class="control-wrapper">
          <input type="text" id="dunsNumber" name="dunsNumber" formControlName="duns" placeholder=""
            class="form-control form-control-sm"
            [class.is-invalid]="accountForm.controls['duns'].invalid && (accountForm.controls['duns'].dirty || accountForm.controls['duns'].touched)">
          <div
            *ngIf="accountForm.controls['duns'].invalid && (accountForm.controls['duns'].dirty || accountForm.controls['duns'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['duns'].errors?.['required']">
              This field is required.
            </div>
            <div *ngIf="accountForm.controls['duns'].errors?.['pattern']">
              Numbers are allowed only.
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="orderNumber" class="col-sm-4 col-form-label">Order Number </label>
        <div class="control-wrapper">
          <input type="text" id="orderNumber" name="orderNumber" formControlName="orderNumber" placeholder=""
            class="form-control form-control-sm"
            [class.is-invalid]="accountForm.controls['orderNumber'].invalid && (accountForm.controls['orderNumber'].dirty || accountForm.controls['orderNumber'].touched)">
          <div
            *ngIf="accountForm.controls['orderNumber'].invalid && (accountForm.controls['orderNumber'].dirty || accountForm.controls['orderNumber'].touched)"
            class="invalid-feedback">
            <div *ngIf="accountForm.controls['orderNumber'].errors?.['required']">
              This field is required.
            </div>
            <div *ngIf="accountForm.controls['orderNumber'].errors?.['pattern']">
              Enter numbers and/or letters.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="action-wrapper">
      <button type="button" class="button mr-2" (click)="validate()">Save</button>
      <a routerLink="/" class="button">Cancel</a>
    </div>
  </form>
</div>
