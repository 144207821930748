<div class="wrapper">
  <p-breadcrumb [model]="items" *ngIf="!isCustomFilter"></p-breadcrumb>
  <div class="topText">
    <h1 class="heading-text">Audit Trail</h1>
  </div>
  <div class="grid-wrapper">
    <p-table #dt [value]="auditDataList" dataKey="auditId" [resizableColumns]="false" [autoLayout]="true"
      [paginator]="true" [alwaysShowPaginator]="true" [rows]="pageSize" [totalRecords]="totalPages" [lazy]="true"
      sortMode="single" (onLazyLoad)="handleLazyLoad($event)" paginatorDropdownAppendTo="body"
      [showCurrentPageReport]="true" [first]="first" styleClass="p-datatable-striped"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records">
      <ng-template pTemplate="header" (onPage)="onPagination()">
        <tr>
          <th class="chevronCol"></th>
          <th *ngIf="!isCustomFilter">Name
          </th>
          <th>Operation Type
          </th>
          <th *ngIf="!isCustomFilter">Request Type
          </th>
          <th>User
          </th>
          <th>Event Date
          </th>
        </tr>
        <tr class="filterRow" *ngIf="!isCustomFilter">
          <th class="chevronCol"></th>
          <th>
            <input type="text" field="name" (keyup.enter)="filterColumn('name', $event)" placeholder="Search" />
          </th>
          <th>
            <select (change)="filterColumn('operationType', $event)">
              <option value="">Select Type</option>
              <option *ngFor="let obj of operationType" [ngValue]="obj.description">{{obj.description}}</option>
            </select>
          </th>
          <th>
            <select (change)="filterColumn('requestType', $event)">
              <option value="">Select Type</option>
              <option *ngFor="let obj of requestType" [ngValue]="obj.description">{{obj.description}}</option>
            </select>
          </th>
          <th>
            <input type="text" field="modifiedBy" (keyup.enter)="filterColumn('modifiedBy', $event)"
              placeholder="Modified By" />
          </th>
          <th>
            <p-calendar field="auditDate" selectionMode="range" [(ngModel)]="rangeVal" [readonlyInput]="false"
              inputId="range" class="rangeDate" #range dateFormat="dd/mm/yy" appendTo="body" placeholder="MM/DD/YYYY - MM/DD/YYYY">
              <p-header>
                <div class="dateHeader">
                  <button (click)="filterRangeColumn('auditDate', range.value); range.hideOverlay()" class="button secondary">Apply</button>
                  <button (click)="filterRangeColumn('auditDate', []); range.hideOverlay();" class="button secondary clearButton">Clear</button>
                </div>
              </p-header>
            </p-calendar>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-audit let-expanded="expanded">
        <tr>
          <td>
            <button type="button" pButton pRipple [pRowToggler]="audit"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td *ngIf="!isCustomFilter">{{audit.name}}</td>
          <td>{{audit.operationType}}</td>
          <td *ngIf="!isCustomFilter">{{audit.requestType}}</td>
          <td>{{audit.modifiedBy}}</td>
          <td>{{audit.auditDate}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-audit>
        <tr>
          <td colspan="6">
            <app-show-audit-details [auditId]="audit.auditId" *ngIf="!isCustomFilter">
            </app-show-audit-details>
            <app-show-audit-without-tab [auditId]="audit.auditId" [type]="inputFilter.type" *ngIf="isCustomFilter">
            </app-show-audit-without-tab>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
