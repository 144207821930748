/* Authentication Roles */
export const ACCOUNT_VIEW = "RPSI_CONFIG_ACCOUNT_VIEW_ACCESS";
export const ACCOUNT_CREATE = "RPSI_CONFIG_ACCOUNT_CREATE";
export const ACCOUNT_UPDATE = "RPSI_CONFIG_ACCOUNT_UPDATE";
export const ACCOUNT_ALL = "RPSI_CONFIG_ACCOUNT_ALL";
export const CONFIGURATION_VIEW = "RPSI_CONFIG_CONFIGURATION_VIEW";
export const CONFIGURATION_CREATE = "RPSI_CONFIG_CONFIGURATION_CREATE";
export const CONFIGURATION_UPDATE = "RPSI_CONFIG_CONFIGURATION_UPDATE";
export const CONFIGURATION_ALL = "RPSI_CONFIG_CONFIGURATION_ALL";

/*  */
