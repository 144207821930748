import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AuditLog } from 'src/app/models/auditModal';
import { AccountDataService } from 'src/app/services/account-data.service';

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss']
})
export class AuditTrailComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() inputFilter = {
    type: "",
    id: ""
  };
  public isCustomFilter = false;
  public auditDataList: Array<AuditLog> = [];
  public candidatesMatchedQuantity!: number;
  public totalPages!: number;
  public pageNum!: number;
  public pageSize: number = 10;
  public errorMessage = '';
  public sort = true;
  public filterParams = new URLSearchParams();
  public filterBy: string = '';
  public rangeVal: string = '';
  private unsubscribe$ = new Subject<void>();
  public operationType = [
    { code: 'Updated', description: 'Updated' },
    { code: 'Created', description: 'Created' }
  ];
  public requestType = [
    { code: 'Account', description: 'Account' },
    { code: 'Configuration', description: 'Configuration' }
  ];
  items: MenuItem[] = [
    { label: 'Account Details', routerLink: '/' },
    { label: 'Audit Details' }
  ];

  constructor(
    private accountService: AccountDataService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this.inputFilter?.type !== '') {
      this.isCustomFilter = true;
      this.filterParams.set('requestType', this.inputFilter.type);
      this.filterParams.set('id', this.inputFilter.id);
    }
  }

  public getAllData(): void {
    this.pageNum = this.pageNum || 1;
    this.pageSize = this.pageSize ? this.pageSize : 10;
    this.filterParams.set('pageNo', '' + this.pageNum)
    this.filterParams.set('pageSize', '' + this.pageSize)
    const params = '?' + this.filterParams.toString();
    this.accountService
      .getAuditsList(params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.auditDataList = result.responseData.auditLog;
          this.totalPages = parseInt(result.responseData.resultSummary.candidatesMatchedQuantity);
        }
      });
  }
  public filterColumn(columnName: string, event: any) {
    let value = event?.target?.value?.trim() || '';
    if (value !== '') {
      this.filterParams.set(columnName, value);

    } else {
      this.filterParams.delete(columnName);
    }
    this.pageNum = 1;
    this.getAllData();
  }

  public filterRangeColumn(columnName: string, value: Date[]) {
    let start = '';
    let end = '';
    if (columnName == 'auditDate' && value?.length) {
      start = this.getFormattedDate(value[0]);
      end = this.getFormattedDate(value[1]);
      this.filterParams.set('startDate', start);
      this.filterParams.set('endDate', end);
    } else {
      this.rangeVal = '';
      this.filterParams.delete('startDate');
      this.filterParams.delete('endDate');
    }
    this.pageNum = 1;
    this.getAllData();
  }

  public handleLazyLoad(event: LazyLoadEvent): void {
    this.pageNum = Math.floor(((event.first || 1) / (event.rows || 1)) + 1);
    this.getAllData();
  }
  public get first(): number {
    return (this.pageNum - 1) * this.pageSize;
  }
  onPagination() {
    this.getAllData();
  }

  getFormattedDate(date: Date) {
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let day = ('0' + (date.getDate())).slice(-2);
    let year = date.getFullYear();
    return year + '-' + month + '-' + day;
  }
  // Function required to support dynamic data update in PTable and its filter contorols
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }
  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
