<div class="wrapper">
  <p-breadcrumb [model]="items"></p-breadcrumb>
  <div class="topText">
    <h1 class="heading-text">Customer Usage Summary ({{accountName}})</h1>
  </div>
  <div class="form-group">
    <div>Select a date range to identify usage information.</div>
  </div>
  <div class="dateWrapper">
    <div class="form-group">
      <label for="StartDate" class="col-sm-4 col-form-label">From <span
          class="required">*</span></label>
      <div class="control-wrapper">
        <p-calendar appendTo="body" id="StartDate" name="StartDate" [(ngModel)]="fromDate" [showIcon]="true"
          dateFormat="mm/dd/yy" [maxDate]="maxDate"  placeholder="MM/DD/YYYY">
        </p-calendar>
      </div>
    </div>
    <div class="form-group">
      <label for="EndDate" class="col-sm-4 col-form-label">To <span class="required">*</span></label>
      <div class="control-wrapper">
        <p-calendar appendTo="body" id="EndDate" name="EndDate" [(ngModel)]="toDate" [showIcon]="true"
          dateFormat="mm/dd/yy" [maxDate]="maxDate" placeholder="MM/DD/YYYY">
        </p-calendar>
      </div>
    </div>
    <div class="form-group">
      <div class="control-wrapper">
        <button (click)="fetchSummary()" class="button button-primary">Go</button>
      </div>
    </div>
  </div>
  <div *ngIf="error" class="invalid-feedback">
    {{message}}
  </div>
  <div class="grid-wrapper" *ngIf="showGrid">
    <div class="setupDetailWrapper">
      <h2>Summary View</h2>
      <button (click)="exportDetails()" class="button button-primary">Export Details</button>
    </div>
    <p-table #dt [value]="usageList" dataKey="usageId" [resizableColumns]="false" [autoLayout]="true" [paginator]="true"
      [alwaysShowPaginator]="true" [rows]="pageSize" [totalRecords]="totalPages" [lazy]="true" sortMode="single"
       paginatorDropdownAppendTo="body" [showCurrentPageReport]="true"
      [first]="first" styleClass="p-datatable-striped"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records">
      <ng-template pTemplate="header" (onPage)="onPagination()">
        <tr>
          <th>Year</th>
          <th>Month</th>
          <th class="text-right">Search</th>
          <th class="text-right">WLS</th>
          <th class="text-right">WLS Custom</th>
          <th class="text-right">PEPD</th>
          <th class="text-right">GOV</th>
          <th class="text-right">NMEDIA</th>
          <th class="text-right">Person</th>
          <th class="text-right">Business</th>
          <th class="text-right">Portal</th>
          <th class="text-right">Batch</th>
          <th class="text-right">API</th>
          <th class="text-right">Monitoring</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{item.Year}}</td>
          <td>{{item.Month}}</td>
          <td class="text-right">{{item.Searches | number:'1.0':'en-US'}}</td>
          <td class="text-right">{{item.WLS | number:'1.0':'en-US'}}</td>
          <td class="text-right">{{item.WLS_Custom | number:'1.0':'en-US'}}</td>
          <td class="text-right">{{item.PEPD | number:'1.0':'en-US'}}</td>
          <td class="text-right">{{item.GOV | number:'1.0':'en-US'}}</td>
          <td class="text-right">{{item.NMEDIA | number:'1.0':'en-US'}}</td>
          <td class="text-right">{{item.Person | number:'1.0':'en-US'}}</td>
          <td class="text-right">{{item.Business | number:'1.0':'en-US'}}</td>
          <td class="text-right">{{item.Portal | number:'1.0':'en-US'}}</td>
          <td class="text-right">{{item.Batch | number:'1.0':'en-US'}}</td>
          <td class="text-right">{{item.API | number:'1.0':'en-US'}}</td>
          <td class="text-right">{{item.Monitoring | number:'1.0':'en-US'}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
