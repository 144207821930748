import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { ConfigDataService } from 'src/app/services/config-data.service';
import { CONFIGURATION_UPDATE, CONFIGURATION_ALL } from '../../constants';

@Component({
  selector: 'app-view-configuration',
  templateUrl: './view-configuration.component.html',
  styleUrls: ['./view-configuration.component.scss']
})
export class ViewConfigurationComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  accountId: string = '';
  configId: string = '';
  accountName = '';
  configData!: GetConfigurationResponse;
  public watchList = {} as ScreeningLevel;
  public political = {} as ScreeningLevel;
  public adverseMedia = {} as ScreeningLevel;
  items: MenuItem[] = [
    { label: 'Account Details', routerLink: '/config/account/list' },
    { label: 'View Configuration Details' }];
  showAudit = false;
  filterObject = {
    type: "Configuration",
    id: ""
  };
  editConfigPermissions = [CONFIGURATION_UPDATE, CONFIGURATION_ALL];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigDataService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.getConfigurationById(params['accountId'], params['configId']);
      });
  }

  getConfigurationById(accountId: string, configId: string) {
    this.accountId = accountId;
    this.configId = configId;
    this.configService.getConfigurationsById(accountId, configId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result?.responseData) {
          this.configData = result.responseData.configurationResponse;
          this.filterObject.id = this.configId;
          this.configData.screeningLevels.map((screeningLevel: ScreeningLevel) => {
            switch (screeningLevel.code) {
              case '1':
                this.watchList = screeningLevel;
                break;
              case '2':
                this.political = screeningLevel;
                break;
              case '3':
                this.adverseMedia = screeningLevel;
                break;
            }
          });
        }
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
